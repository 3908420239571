import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import ImageEditor from "@uppy/image-editor"
import Tus from "@uppy/tus"
import Russian from '@uppy/locales/lib/ru_RU'
import { toastrNotification } from '../helpers/Toastr'

import '@uppy/image-editor/dist/style.css'

export default class extends Controller {

  static targets = ["image", "pupilslist"]

  static values = {
    urlUpdate: String,
    noImageUrl: String,
    maxVideoSize: Number,
  }

  connect() {
    // this.pupilslistTarget.addEventListener('change', this.changePupil.bind(this))
    document.addEventListener('click', (event) => {
      if (event.target.closest('.access_mode')) {
        console.log(event.target.value);
        const accessMode = event.target.value;
        switch (accessMode) {
          case 'public':
            document.querySelector('.pupilslist').classList.add('d-none');
            document.querySelector('.sale_price').classList.add('d-none');
            document.querySelector('.subtitle').classList.add('d-none');
            document.querySelector('.description').classList.add('d-none');
            break;
          case 'for_pupils':
            document.querySelector('.pupilslist').classList.remove('d-none');
            document.querySelector('.sale_price').classList.add('d-none');
            break;
          case 'for_sale':
            document.querySelector('.sale_price').classList.remove('d-none');
            document.querySelector('.subtitle').classList.remove('d-none');
            document.querySelector('.description').classList.remove('d-none');
            document.querySelector('.pupilslist').classList.add('d-none');
            break;
          default:
            console.log('Unknown access mode selected');
          // Add your code for unknown access mode here
        }
      }
    });
  }

  changeImage(event) {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      },
      autoProceed: false,
      locale: Russian
    })
    uppy.use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5 * 1024 * 1024 })
    uppy.use(Dashboard, {
      proudlyDisplayPoweredByUppy: false,
      autoOpenFileEditor: true,
      hideCancelButton: true
    })
    uppy.use(ImageEditor, {
      target: Dashboard,
      quality: 1,
      cropperOptions: {
        viewMode: 1,
        background: true,
        autoCropArea: 0.8,
        responsive: true,
        aspectRatio: 4 / 3,
        croppedCanvasOptions: { width: 640, height: 480 },
      },
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
    })

    uppy.on('upload-success', (file, response) => {
      let fileData = {
        title: file.name,
        file_orig_name: file.name,
        file_url: response.uploadURL,
        file_size: file.size,
        file_type: file.meta.type
      }
      this.imageTarget.src = file.preview
      if (isFileImage(file)) {
        createImageFile(fileData)

      }
      uppy.getPlugin('Dashboard').closeModal();
    });

    uppy.getPlugin('Dashboard').openModal();
  }
}

function createImageFile(fileData) {
  const parameters = JSON.stringify({ file: fileData });
  const token = document.getElementsByName("csrf-token")[0].content;

  fetch('/api/v1/files.json', {
    body: parameters,
    method: 'POST',
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "X-CSRF-Token": token
    },
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      document.getElementById("image_id").value = data["content_id"]
    })
    .catch((e) => {
      toastrNotification({ type: 'error', title: window.t('news_items.error_image_loading') })
    })
}

function isFileImage(file) {
  return file && file['type'].split('/')[0] === 'image';
}