import React from 'react'
import { client } from '../Client'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'

class Price extends React.Component {

  render() {
    const course = this.props.course
    const locale = window.locale || 'ru'
    const maxCoursePrice = course.author.payment_provider === 'tinkoff' ? 30000 : 1000000
    var discountStatus = parseInt(course.discount_price) > 0

    const validationSchema = Yup.object().shape({
      price: Yup.number()
        .required(window.t('form.required'))
        .min(-1)
        .max(maxCoursePrice, window.t('form.must_be_less_or_equal_to') + ' ' + maxCoursePrice)
        .integer(),
      discount_price: Yup.number()
        .min(0)
        .integer()
        .max(Yup.ref('price'), window.t('course.less_then_price'))
    })

    function toggleDiscountField() {
      document.querySelector("input[name='discount_price']").toggleAttribute('hidden')
      document.querySelector(".discount-hint").toggleAttribute('hidden')
      discountStatus = !discountStatus
    }


    return (
      <div className="container">
        <div className="card bg-transparent border course-creator-content mb-5">
          <div className="card-body">
            <div className="bs-stepper-content">
              <h4>{window.t('course.price')}</h4>
              <hr />

              {!course.ready_for_publication &&
                <div className="row">
                  <div className="col-12 col-md-6 offset-md-3 text-center text-warning mg-b-15">
                    {course.author.payment_provider === 'mkb'
                      ? window.t('course.contract_is_needed')
                      : <div>
                        {window.t('course.add_bank_card_warning')} <br />
                        <a href={Routes.tutor_bank_cards_path(locale)}>{window.t('course.add_bank_card')}</a>
                      </div>
                    }
                    <div className='my-1'>
                      <span className='text-secondary'>
                        {window.t('course.for_free_course')}
                      </span>
                    </div>
                  </div>
                </div>
              }

              <div className="row">
                <div className="col-12 offset-md-4 col-md-4 col-sm-6 offset-sm-3">

                  <div className="bg-light border rounded p-3">
                    <h5>{window.t('course.set_course_price')}</h5>

                    <Formik
                      initialValues={{
                        price: course.price,
                        discount_price: course.discount_price
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {

                        if (values.discount_price == '' || values.discount_price == null) { discountStatus = false }
                        if (!discountStatus) document.getElementById("enable_discount").checked = false
                        let data = {}
                        data['course_price_attributes'] = values

                        client.updateCourse(course.id, data, discountStatus)
                          .then((course) => {
                            if (course.id) {
                              toastrNotification({ type: 'success', title: window.t('updated') })
                              this.props.onUpdateCourse(course)
                            } else {
                              toastrNotification({ type: 'error', title: window.t('not_updated') })
                            }
                            setSubmitting(false)
                          })
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="form-label">{window.t('course.price')}</label>
                              <input
                                type="number"
                                min="0"
                                step="1"
                                name="price"
                                className={"form-control " + (errors.price && touched.price ? 'is-invalid' : '')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                                placeholder={window.t('course.price_placeholder')}
                                disabled={!course.ready_for_publication}
                              />
                              {errors.price && touched.price &&
                                <div className="invalid-feedback">{errors.price}</div>
                              }
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-label me-3">{window.t('course.discount_price')}</label>
                            <input type="checkbox" name="enable_discount"
                              onChange={toggleDiscountField}
                              id="enable_discount"
                              defaultChecked={discountStatus} />
                            <input
                              type="number"
                              min="0"
                              step="1"
                              name="discount_price"
                              hidden={!discountStatus}
                              className={"form-control " + (errors.discount_price && touched.discount_price ? 'is-invalid' : '')}
                              onChange={handleChange}

                              onBlur={handleBlur}
                              value={values.discount_price}
                              placeholder={window.t('course.discount_price_placeholder')}
                              disabled={!course.ready_for_publication}
                            />
                            {errors.discount_price && touched.discount_price &&
                              <div className="invalid-feedback">{errors.discount_price}</div>
                            }
                            <div className='discount-hint'
                              hidden={!discountStatus}
                            >
                              {values.discount_price === 0 &&
                                <small>{window.t('course.discount_price_hint')}</small>
                              }
                              {values.discount_price > 0 && values.price > 0 &&
                                <small>{window.t('course.has_discount')} <span className="text-danger">{Math.round((values.price - values.discount_price) / values.price * 100)}%</span></small>
                              }
                            </div>
                          </div>

                          {course.ready_for_publication &&
                            <div className="row mt-3">
                              <div className="col-12 text-end">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting || !isValid}>
                                  {window.t('save')}
                                </button>
                              </div>
                            </div>
                          }
                        </form>
                      )}
                    </Formik>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    )
  }
}

export default Price