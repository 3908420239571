document.addEventListener('turbo:load', () => {
  let nav = document.querySelector('.nav-scroller nav')

  if (nav) {
    setTimeout(function()
    {
      let navLinks = document.querySelectorAll('.nav-scroller nav a')
      let activeLink = document.querySelector('.nav-scroller nav a.active')
      activeLink.scrollIntoView({ behavior: "smooth", inline: "center" })
    }, 2000)
  }
})