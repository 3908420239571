import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  connect() {
    video = $('video')
    $('video').prop('muted', false)
  }

  disconnect(){
    if ( video ) {
      $('video').prop('muted', true)
    }
  }
}
var video
