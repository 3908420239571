import React, { useState } from 'react'
import { useInputValue } from '../hooks'
import { client } from '../../Client'
import { toastrNotification } from '../../../helpers/Toastr'

export default function LectionForm({ lection, course, updateLectionTitle, updateLectionAssessment }) {
  const [title, setTitle] = useInputValue(lection.title)
  const [assessment, setAssessment] = useState(lection.assessment)

  function handleAssessmentChange(event) {
    const newAssessment = event.target.checked
    setAssessment(newAssessment)
    lection.assessment = newAssessment
  }

  function saveTitle() {
    client.updateCourseLection(course.id, lection.course_section_id, lection.id, { title: title })
      .then(response => {
        if (response.id) {
          updateLectionTitle(title)
          toastrNotification({ type: 'success', title: window.t('updated') })
        } else {
          toastrNotification({ type: 'error', title: window.t('not_updated') })
        }
      })
  }

  function saveAssessment() {
    client.updateCourseLection(course.id, lection.course_section_id, lection.id, { assessment: assessment })
      .then(response => {
        if (response.id) {
          updateLectionAssessment(assessment)
          toastrNotification({ type: 'success', title: window.t('updated') })
        } else {
          toastrNotification({ type: 'error', title: window.t('not_updated') })
        }
      })
  }

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card border rounded-3">
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-12">
                <label htmlFor="lectionTitle">{window.t("course.lection_title")}</label>
                <input type="text" value={title} onChange={setTitle} onBlur={saveTitle} className="form-control" id="lectionTitle" />
              </div>
              <div className="form-group col-12">
                <div className="form-check">
                  <input type="checkbox" checked={assessment} onChange={handleAssessmentChange} onBlur={saveAssessment} className="form-check-input" id="assessmentCheckbox" />
                  <label className="form-check-label" htmlFor="assessmentCheckbox">{window.t("course.assessment")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
