import React from 'react'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import { client } from '../Client'

function renderDocViewer(lection) {
  if (client.checkReactViewerExt(lection.content.name) == true) {
    return (
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={[{ uri: lection.content.url }]}
        style={{ minHeight: '100vh' }}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false
          }
        }}
      />
    )
  }
  else {
    return (
      <div className='text-center'>
        <span className="text-danger">
          {window.t('document.file_format_not_supported')}
          <a href="#" onClick={(e) => { client.downloadLectionFile(e, lection) }}>{lection.content.name}</a>
        </span>
      </div>
    )
  }
}

const ContentDocument = React.memo(props => {
  const lection = props.lection

  return (
    <div className="vh-100 overflow-auto">
      {renderDocViewer(lection)}
    </div>
  )
})

export default ContentDocument