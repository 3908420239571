import React from 'react'
import { NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'

const courseBadge = (status) => {
  switch (status) {
    case 'draft':
      return <span className="badge bg-secondary text-white mg-l-10" style={{ alignSelf: 'baseline' }}>{window.t('course.draft')}</span>
    case 'for_sale':
      return <span className="badge bg-success text-white mg-l-10" style={{ alignSelf: 'baseline' }}>{window.t('course.for_sale')}</span>
    case 'temporary_block':
      return <span className="badge bg-success text-white mg-l-10" style={{ alignSelf: 'baseline' }}>{window.t('course.temporary_block')}</span>
    default:
      return null
  }
}

const CourseCreatorHeader = (props) => {
  const { course, locale, pathname, license } = props

  return (
    <>
      <div className="container course-creator-stepper">
        <div className="card bg-transparent border course-creator-header">
          <div id="stepper" className="bs-stepper stepper-outline">
            <div className="card-header bg-light px-3 pb-3">
              <ul className="nav nav-line nav-line-profile">
                <li className="nav-item">
                  <NavLink to={Routes.edit_tutor_course_path(course.slug, { locale: locale })} className="nav-link">
                    <div className="step" data-target="#step-1">
                      <div className="d-grid text-center align-items-center">
                        <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger1" aria-controls="step-1">
                          <span className="bs-stepper-circle">1</span>
                        </button>
                        <h6 className="bs-stepper-label d-none d-md-block">
                          {course.stream ? window.t('course.stream_details') : window.t('course.details')}
                        </h6>
                      </div>
                    </div>
                  </NavLink>
                </li>

                <div className="line"></div>

                {course.package
                  ?
                  <li className="nav-item">
                    <NavLink to={Routes.tutor_course_package_items_path(course.slug, { locale: locale })} className="nav-link">
                      <div className="step" data-target="#step-2">
                        <div className="d-grid text-center align-items-center">
                          <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger1" aria-controls="step-2">
                            <span className="bs-stepper-circle">2</span>
                          </button>
                          <h6 className="bs-stepper-label d-none d-md-block">
                            {window.t('course.package_items')}
                          </h6>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  : course.general ?
                    <li className="nav-item">
                      <NavLink to={Routes.tutor_course_curriculum_path(course.slug, { locale: locale })} className="nav-link">
                        <div className="step" data-target="#step-2">
                          <div className="d-grid text-center align-items-center">
                            <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger1" aria-controls="step-2">
                              <span className="bs-stepper-circle">2</span>
                            </button>
                            <h6 className="bs-stepper-label d-none d-md-block">
                              {window.t('course.curriculum')}
                            </h6>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    :
                    <li className="nav-item">
                      <NavLink to={Routes.tutor_course_curriculum_path(course.slug, { locale: locale })} className="nav-link">
                        <div className="step" data-target="#step-2">
                          <div className="d-grid text-center align-items-center">
                            <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger1" aria-controls="step-2">
                              <span className="bs-stepper-circle">2</span>
                            </button>
                            <h6 className="bs-stepper-label d-none d-md-block">
                              {window.t('course.curriculum')}
                            </h6>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                }

                {!course.apple_user ?
                  <div className="line"></div>
                  :
                  ''
                }

                {!course.apple_user ?

                  <li className="nav-item">
                    <NavLink to={Routes.tutor_course_price_path(course.slug, { locale: locale })} className="nav-link">
                      <div className="step" data-target="#step-3">
                        <div className="d-grid text-center align-items-center">
                          <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger3" aria-controls="step-3">
                            <span className="bs-stepper-circle">3</span>
                          </button>
                          <h6 className="bs-stepper-label d-none d-md-block">
                            {window.t('course.price')}
                          </h6>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  : ''
                }

                <div className="line"></div>

                <li className="nav-item">
                  <NavLink to={Routes.tutor_course_publication_path(course.slug, { locale: locale })} className="nav-link">
                    <div className="step" data-target="#step-3">
                      <div className="d-grid text-center align-items-center">
                        <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger3" aria-controls="step-3">
                          <span className="bs-stepper-circle">{!course.apple_user ? '4' : '3'}</span>
                        </button>
                        <h6 className="bs-stepper-label d-none d-md-block">
                          {window.t('course.publication')}
                        </h6>
                      </div>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="nav-scroller bg-body shadow-sm mb-3">
        <nav className="nav nav-underline">
          <NavLink to={Routes.edit_tutor_course_path(course.slug, { locale: locale })} className="nav-link">
            {course.stream ? window.t('course.stream_details') : window.t('course.details')}
          </NavLink>
          {course.package
            ?
            <NavLink to={Routes.tutor_course_package_items_path(course.slug, { locale: locale })} className="nav-link">
              {window.t('course.package_items')}
            </NavLink>
            :
            <NavLink to={Routes.tutor_course_curriculum_path(course.slug, { locale: locale })} className="nav-link">
              {window.t('course.curriculum')}
            </NavLink>
          }
          {!course.apple_user ?
            <NavLink to={Routes.tutor_course_price_path(course.slug, { locale: locale })} className="nav-link">
              {window.t('course.price')}
            </NavLink>
            : ''
          }
          <NavLink to={Routes.tutor_course_publication_path(course.slug, { locale: locale })} className="nav-link">
            {window.t('course.publication')}
          </NavLink>
        </nav>
      </div>
    </>
  )
}

export default CourseCreatorHeader
