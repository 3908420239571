import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    newsId: String
  }

  regenerateSnapshot(){
    let progressBar = document.getElementById(this.newsIdValue)
    progressBar.hidden = false
    $(`#${this.newsIdValue}`).find(".progress-bar").animate({
      width: "100%"
    }, 250 );
  }

}

