import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { duration } from "moment/moment"

export default class extends Controller {

  static targets = [ "input", "progress" ]

  static values = {
    urlUpdate: String,
  }

  disconnect()
  {
    clearInterval(progressBarTimer)  
    if(videoPlayer){
      videoPlayer.pause()
      videoPlayer.removeAttribute('src')
      videoPlayer.load()
    }
  }
  connect(){

    currentProgressBar = 0
    progressBars = this.progressTarget.querySelectorAll('.progress-bar')
    progressBars.forEach( (e, index) => {
      if(e.dataset.active == 'true')currentProgressBar = index
    })
    $(document).on('contextmenu', function (e) {
      // e.preventDefault()
    });
      
    videoPlayer = document.querySelector('.main_video')
    storyImage = document.querySelector('.main_image')
    
    onclick = (e) => {
      storyImage = document.querySelector('.main_image')
      videoPlayer = document.querySelector('.main_video')
      if(videoPlayer && e.target.classList.contains('main_video')){
        if(videoPlayer.paused){
          videoPlayer.play()
          pause = false
       } 
       else{
        videoPlayer.pause()
        pause = true
       }  
        // arguments[0].preventDefault();
      }
      let playerPosition = document.querySelector('.story_play').getBoundingClientRect()
      if(e.target.classList.contains('mute-toggle'))muteToggle()
      if(e.target == storyImage || e.target == videoPlayer) {
        if( (e.clientX  - playerPosition.left) > e.target.getBoundingClientRect().width - tapWidth && currentProgressBar < progressBars.length -1){
          progressBars[currentProgressBar].style.width = '100%'
          currentProgressBar ++
          showStory(progressBars[currentProgressBar].dataset)
        }
        if( (e.clientX - playerPosition.left) < tapWidth && currentProgressBar > 0 ){
          progressBars[currentProgressBar].style.width = '0%'
          currentWidth = '0%'
          currentWidthFloat = 0
          currentProgressBar --
          showStory(progressBars[currentProgressBar].dataset)
        }

      }
      
    }
    showStory(progressBars[currentProgressBar].dataset)
    progressBarTimer = setInterval(() => fillProgressBar(progressBars), timeQuant)
  }
  
  stopStory(event) {
    storyImage = document.querySelector('.main_image')
    if(event.touches[0].clientX < storyImage.getBoundingClientRect().width - tapWidth && event.touches[0].clientX > tapWidth){
      event.preventDefault();
    }
    pause = true
    ontouchend = (event) => {
      pause = false
    }
      // event.currentTarget.removeEventListener(event.type, handler);
  }

  
}

var progressBarTimer, currentProgressBar
var progressBars
var storyImage
var currentWidth = 0
var currentWidthFloat = 0
var currentVideoDuration = 1
var videoPlayer = null
var pause = false
var muted = true
const timeQuant = 100// mscecunds
var progressBarWidth
const staticImageDuration = 5000 //msec
const tapWidth = 250
var delta = 1

function muteToggle(){
  videoPlayer = document.querySelector('.main_video')
  videoPlayer.muted = !videoPlayer.muted
   muted = videoPlayer.muted
  if(muted){
    muteToggleOn()
  }
  else{
    muteToggleOff()
  }
}

function fillProgressBar(progressBars){


  if(pause == false ){
    videoPlayer = document.querySelector('video')
    currentWidth = parseInt(progressBars[currentProgressBar].style.width)
    currentWidthFloat += delta
    progressBars[currentProgressBar].style.width = (currentWidthFloat + delta) + '%'
    if(currentWidth >= 100){
      if(!videoPlayer || videoPlayer.duration == videoPlayer.currentTime){
        currentProgressBar ++
        if(currentProgressBar > progressBars.length - 1){
          currentProgressBar --
          clearInterval(progressBarTimer)  
        }
        else {
        clearInterval(progressBarTimer)  
        showStory(progressBars[currentProgressBar].dataset)
        }
      }
    }
  }
}

async function showStory(dataset){
  if(dataset.video){
    muteToggleOn()
  }
  else{
    muteToggleHide()
  }
  currentWidth = 0
  currentWidthFloat = 0
  let url = dataset.url
  progressBars[currentProgressBar].style.width = '0%'
  const request = await get(`${url}`, { responseKind: "turbo-stream" })
  if(request.ok){
      videoPlayer = document.querySelector('video')
      if(videoPlayer){
        currentVideoDuration = videoPlayer.duration
        pause = false  
      }
      else{
        currentVideoDuration = dataset.videoduration
      }
      
      if(progressBars[currentProgressBar].dataset.video != ""){
        if(currentVideoDuration){
          delta = 100 / (currentVideoDuration * 1000 / timeQuant)}
        else{
          delta = 1
        }
      }
      else{
        delta = parseInt(100 / (staticImageDuration / timeQuant))
      }
      clearInterval(progressBarTimer)  
      progressBarTimer = setInterval(() => fillProgressBar(progressBars), timeQuant)
    }
}

function muteToggleOn(){
  document.querySelector('.mute-on').setAttribute('style', "display: block;")
  document.querySelector('.mute-off').setAttribute('style', "display: none;")
}

function muteToggleOff(){
  document.querySelector('.mute-on').setAttribute('style', "display: none;")
  document.querySelector('.mute-off').setAttribute('style', "display: block;")
}

function muteToggleHide(){
  document.querySelector('.mute-on').setAttribute('style', "display: none;")
  document.querySelector('.mute-off').setAttribute('style', "display: none;")
}
