import React from 'react'

const BigBlueButton = ({ lection }) => {
  return (
    <div className="lecture-content">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <div className="card overflow-auto" style={{zIndex: 10}}>
              <div className="card-title rounded-3 text-center fw-bold pt-3">{lection.title}</div>
              <div className="rounded-3 mx-3 mt-0 mb-3" dangerouslySetInnerHTML={{ __html: lection.description }}></div>
            </div> */}
            <div class="embedded-content">
              <iframe src={lection.content.url} width="100%" height="200px" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BigBlueButton