import React, { useEffect, useState, useRef } from 'react'
import { client } from '../Client'
import Loader from '../../helpers/Loader'
import FilePreview from '../../helpers/FilePreview'
import HomeworkSubmissionForm from './HomeworkSubmissionForm'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'

const statusBadge = (status) => {
  let badgeClass = ""
  switch (status) {
    case "created":
      badgeClass = "bg-info";
      break;
    case "assigned":
      badgeClass = "bg-primary";
      break;
    case "checking":
      badgeClass = "bg-info";
      break;
    case "accepted":
      badgeClass = "bg-success";
      break;
    case "rejected":
      badgeClass = "bg-danger";
      break;
    default:
      badgeClass = "bg-white";
  }
  return (
    <span className={"badge " + badgeClass}>{ window.t(`submission.${status}`) }</span>
  )
}

export default function ContentHomework({ lection, markLectionCompleted, preview }) {
  const [homework, setHomework] = useState(lection.content.homework)
  const [submissions, setSubmissions] = useState([])
  const [loading, setLoading] = useState(false)
  const [showSubmissionForm, setShowSubmissionForm] = useState(false)
  const [currentSubmission, setCurrentSubmission] = useState(null)
  const [tab, setTab] = useState('details')
  const [homeworkInstance, setHomeworkInstance] = useState(null)
  const filesUploader = useRef(null)

  useEffect(() => {
    getHomeworkSubmission()
  }, [])

  const getHomeworkSubmission = () => {
    setLoading(true)
    client.createHomeworkInstance(homework.id)
    .then(instance => {
      setHomeworkInstance(instance)
      client.getHomeworkSubmissions(homework.id, instance.id)
      .then(submissions => {
        setSubmissions(submissions)
        setLoading(false)
        if (submissions.length > 0) {
          setTab('submissions')
        }
      })
    })
  }

  const renderAttachmentLink = (attachment) => {
    if (attachment.file.is_video) {
      return (<a href={Routes.pupil_video_path(locale, attachment.file.content_id)} data-modal="true">{ attachment.file.name }</a>)
    } else {
      return (<FilePreview file={attachment.file} />)
    }
  }

  const setCurrentTab = (e, currentTab) => {
    e.preventDefault()
    setTab(currentTab)
  }

  const openFilesUploader = () => {
    filesUploader.current?.open(homework, homeworkInstance.id)
  }

  const openSubmissionForm = () => {
    setTab("submissions")
    setShowSubmissionForm(true)
  }

  const closeSubmissionForm = () => {
    setTab("submissions")
    setShowSubmissionForm(false)
  }

  const addNewSubmission = () => {
    client.createHomeworkSubmission(homework.id, homeworkInstance.id)
    .then(submission=> {
      if (submission.id) {
        setShowSubmissionForm(true)
        setCurrentSubmission(submission)
      } else {
        toastr.error("Unable to add submission")
      }
    })
  }

  const deleteSubmission = (e, submission) => {
    e.preventDefault()
    client.deleteHomeworkSubmission(homework.id, homeworkInstance.id, submission.id)
    .then(response => {
      //setSubmissions(submissions.filter(sub => sub.id !== submission.id))
      //Turbo.visit(window.location)
      getHomeworkSubmission()
    })
  }

  const editSubmission = (e, submission) => {
    e.preventDefault()
    setCurrentSubmission(submission)
    openSubmissionForm()
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-baseline">
              <h5>{ homework.title }</h5>
              { homeworkInstance && statusBadge(homeworkInstance.status) }
            </div>

            { loading
              ?
                <Loader />
              :
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="nav nav-line nav-line-profile">
                      <li className="nav-item">
                        <a aria-current="page" className={"nav-link " + (tab == "details" ? "active" : "")} onClick={(e) => setCurrentTab(e, "details")} href="#">{ window.t("homework.details") }</a>
                      </li>
                      <li className="nav-item">
                        <a aria-current="page" className={"nav-link " + (tab == "submissions" ? "active" : "")} onClick={(e) => setCurrentTab(e, "submissions")} href="#">{ window.t("homework.submissions") }</a>
                      </li>
                    </div>
                  </div>
                </div>

                { tab === "details"
                  ?
                  <div className="mg-t-20">
                    <div dangerouslySetInnerHTML={{ __html: homework.description }}></div>
                    { homework.attachments.length > 0 &&
                      <ul className="list-unstyled mg-t-15">
                        { homework.attachments.map(attachment => (
                          <li key={attachment.id}>
                            { renderAttachmentLink(attachment) }
                          </li>
                        ))}
                      </ul>
                    }
                  </div>
                  :
                  <div className="mg-t-20">
                    { showSubmissionForm && currentSubmission
                      ?
                      <HomeworkSubmissionForm
                        homeworkId={homework.id}
                        homeworkInstanceId={homeworkInstance.id}
                        submission={currentSubmission}
                        closeForm={closeSubmissionForm}
                      />
                      :
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 mb-3 d-flex">
                              <button className="btn btn-primary ml-auto" onClick={addNewSubmission}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                <span className="mg-l-5">{ window.t("submission.new") }</span>
                              </button>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              { submissions.length === 0 && !loading &&
                                <div className="row">
                                  <div className="col-12 text-center">
                                    { window.t("submission.no_submissions") }
                                  </div>
                                </div>
                              }

                              { submissions.map(submission => (
                                <div className="card mb-3" key={submission.id}>
                                  <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                      <a href="#" onClick={e => editSubmission(e, submission)}>{ window.t("submission.submission_at") } { submission.created_at_s }</a>
                                      <nav className="nav nav-icon-only">
                                        <a className="nav-link dropdown-toggle no-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                          <a className="dropdown-item text-danger" href="#" onClick={e => deleteSubmission(e, submission)}>
                                            <i className="far fa-trash-alt"></i> { window.t("delete") }
                                          </a>
                                        </div>
                                      </nav>
                                    </div>
                                    <div>
                                      { statusBadge(submission.status) }
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}