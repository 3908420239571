import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "btn"]

  connect() {
    if(this.inputTarget.value !== "") {
      this.show()
    }
  }

  show(){
    setTimeout(()=> this.btnTarget.classList.remove("search_wrap-img--hide"), 300)
  }

  execute() {
    this.inputTarget.value = ""
    this.element.requestSubmit()
  }

  active() {
    if(this.inputTarget.value !== "") {
      this.show()
    }
    else {
      this.#hide()
    }
  }

  #hide() {
    this.btnTarget.classList.add("search_wrap-img--hide")
  }
}
