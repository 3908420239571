import React from 'react'
import ReactDOM from 'react-dom'
import ConversationMobile from './ConversationMobile'

document.addEventListener('turbo:load', () => {
  const conversationContainer = document.getElementById('conversationMobileContainer')
  const conversation = window.conversation
  const can_update_conversation = window.can_update_conversation
  const current_user_id = window.current_user_id

  if (conversationContainer) {
    const conversation = JSON.parse(conversationContainer.dataset.conversation),
      canUpdateConversation = conversationContainer.dataset.canUpdateConversation,
      currentUserId = Number(conversationContainer.dataset.currentUserId)

    ReactDOM.render(
      <ConversationMobile
        conversation={conversation}
        canUpdateConversation={canUpdateConversation}
        currentUserId={currentUserId}
      />,
      conversationContainer,
    );

    document.addEventListener('turbo:before-visit', () => {
      ReactDOM.unmountComponentAtNode(conversationContainer)
    })

    document.addEventListener('click', (e) => {
      if (document.querySelector('.edit') && !e.target.classList.contains('more')) {
        document.querySelector('.edit').classList.remove('edit')
      }
      let alreadyOpen = document.querySelector('.room_actions')
      if (alreadyOpen) alreadyOpen.classList.contains('show')
      let menuButton = e.target.classList.contains('open_action')
      if (alreadyOpen && !menuButton) document.querySelector('.room_actions').classList.remove('show')
    })

    document.addEventListener("scroll", (event) => {
      let lastKnownScrollPosition = window.scrollY;
      let dateBage = document.querySelector('.current-date')
      let currentPosition = dateBage.getBoundingClientRect()
      let elementUnderBadge = document.elementFromPoint(currentPosition.x, currentPosition.y)
      let currentDate = elementUnderBadge.parentElement.querySelector('.created-at')
      if (currentDate) dateBage.innerHTML = currentDate.innerHTML
    });
  }
})
