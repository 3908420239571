import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "input", "progress" ]

  static values = {
    urlUpdate: String,
  }
  toggleDescription(event){
    document.querySelector('.show_more').toggleClass('rotate')
    document.querySelector('#description').toggleClass('hide')
  }
 }
