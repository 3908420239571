import { Controller } from "@hotwired/stimulus"
import { toastrNotification } from '../helpers/Toastr'
import { dragdroptouchNotification } from '../helpers/DragDropTouch'
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import ImageEditor from "@uppy/image-editor"
import Tus from "@uppy/tus"
import Russian from '@uppy/locales/lib/ru_RU'

import '@uppy/image-editor/dist/style.css'

export default class extends Controller {

  static targets = ['image', 'imageInput','completed']

    connect() {
      const blocks = Array.from(document.getElementById('certificate').getElementsByClassName('text-block'));

      blocks.forEach((item) =>{
        const objname = document.getElementById(item.getAttribute('id'));
        observer.observe(objname, { attributes: true });
      });

      const cards = document.querySelectorAll('.close-icon');
      cards.forEach(el => el.addEventListener('click', event => addCloseEvent(event)));

      const canvas = document.querySelectorAll('.cert-image')[0];
      const options = document.getElementById('options');

      if (window.innerWidth < 700) {
        options.style.height = "300px";
        const kscale = 1280 / (window.innerWidth - 40);
        window_scale = kscale / 2;
        canvas.style.width = (window.innerWidth - 40) + "px";
        canvas.style.height = (904/kscale) + "px";
        const rangeInputs = Array.from(document.getElementsByClassName('custom-range'));
          rangeInputs.forEach((item) =>{
          item.disabled = true;
        });
        scaleElements(2/(kscale));
      }

      if (window.innerWidth > 700) {
        options.style.height = "600px";
        window_scale = 1;
        canvas.style.width = (1280/2) + "px";
        canvas.style.height = (904/2) + "px";
      }
    }

    protectFromRandomClick(event){
      if (window.innerWidth < 700) {
        event.target.disabled = false;
        setTimeout(function() {
          event.target.disabled = true;
        }, 5000);
      }
    }

    changeImage(event) {
      const locale = window.locale == "ru" ? Russian : 'en_EN'
      const imageWidth = this.imageTarget.dataset.width || 600;
      const imageHeight = this.imageTarget.dataset.height || 600;

      const uppy = new Uppy({
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: ['image/*']
        },
        autoProceed: false,
        locale: locale
      })
      uppy.use(Tus, {endpoint: window.upload_endpoint, chunkSize: 5*1024*1024})
      uppy.use(Dashboard, {
        proudlyDisplayPoweredByUppy: false,
        autoOpenFileEditor: true,
        hideCancelButton: true
      })
      uppy.use(ImageEditor, {
        target: Dashboard,
        quality: 1,
        cropperOptions: {
          viewMode: 1,
          background: true,
          autoCropArea: 0.8,
          responsive: true,
          aspectRatio: 30 / 21,
          croppedCanvasOptions: {
            width: imageWidth,
            height: imageHeight
          },
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false,
        },
      })

      uppy.on('upload-success', (file, response) => {
        let fileData = {title: file.name,
                        file_orig_name: file.name,
                        file_url: response.uploadURL,
                        file_size: file.size,
                        file_type: file.meta.type}
        createFile(fileData, this.imageInputTarget);
        this.imageTarget.src = file.preview;
        uppy.getPlugin('Dashboard').closeModal();
      });

      uppy.getPlugin('Dashboard').openModal();
    }

    save(event) {
      event.preventDefault();

      const parameters = getParameters(window_scale);
      let formData = new FormData();
      formData.append("parameters", JSON.stringify(parameters));
      const token = document.getElementsByName("csrf-token")[0].content;

      fetch(this.data.get("update-url"), {
        body: formData,
        method: 'PATCH',
        dataType: 'script',
        credentials: "include",
        headers: { "X-CSRF-Token": token},
      })
      .then(response => {
        if (response['status'] == 204) {
          toastrNotification({ type: 'success', title: window.t('updated') })
        } else {
          toastrNotification({ type: 'error', title:  window.t('not_updated') })
        }
      });
      return true;
    }

    add(event) {
      event.preventDefault();

      if (document.getElementById('certificate').getElementsByClassName('text-block').length < 8) {
        let copyElement = document.getElementById("usernameBlock").cloneNode(true);
        const number = Math.floor(Math.random()*1000+1);

        copyElement.id = "new" + number + "Block";
        copyElement.setAttribute('name', "new" + number);
        copyElement.innerText = window.t("certificate_template.element") + number;
        copyElement.style.top = 100;
        copyElement.style.left = 10;

        document.getElementById("canvas").appendChild(copyElement);
        observer.observe(copyElement, { attributes: true });

        let copyOptions = document.getElementById("element").cloneNode(true);
        copyOptions.getElementsByClassName("name-element")[0].innerText = window.t("certificate_template.element") + number;

        let closeIcon = copyOptions.getElementsByClassName("close-icon")[0];
        closeIcon.hidden = false;
        closeIcon.addEventListener('click', event=> addCloseEvent(event));

        let optionList = Array.from(copyOptions.getElementsByClassName("options"));
        optionList.forEach((item, i) => {
          item.id = "new" + number;
          if (i==3) item.name = "new" + number + "Width";
        });

        copyOptions.id = "element" + number;
        copyOptions.getElementsByClassName("block-text")[0].style.display="block";
        copyOptions.getElementsByClassName("element-text")[0].value = window.t("certificate_template.element") + number;

        document.getElementById("options").appendChild(copyOptions);
      } else {
        toastrNotification({ type: 'error', title:  window.t("certificate_template.warning_message") })
      }
    }

    drag(event) {
      dx = event.pageX - getOffset(event.target).left;
      dy = event.pageY - getOffset(event.target).top;
    }

    drop(event) {
      const obj = document.getElementById("canvas")
      event.target.style.position = "absolute";
      event.target.style.left = event.pageX - getOffset(obj).left - dx + "px";
      event.target.style.top = event.pageY - getOffset(obj).top - dy + "px";
      if (parseInt(event.target.style.left) <0 ) event.target.style.left = "0px";
      if (parseInt(event.target.style.left) + parseInt(event.target.style.width) > parseInt(canvas.style.width)) {
        event.target.style.left = parseInt(canvas.style.width) - parseInt(event.target.style.width) + "px";
      }
      if (parseInt(event.target.style.top) < 0) event.target.style.top = "0px";
      if (parseInt(event.target.style.top) > parseInt(canvas.style.height)) {
        event.target.style.top = parseInt(canvas.style.height) - parseInt(event.target.offsetHeight) + "px";
      }
    }

    changeFontSize(event) {
      let objname = document.getElementById(event.target.id +"Block");
      objname.style.fontSize = event.target.value + "px";
    }

    changeFontFamily(event) {
      let objname = document.getElementById(event.target.id +"Block");
      objname.style.fontFamily = event.target.options[event.target.selectedIndex].text;
    }

    changeText(event) {
      let objname = document.getElementById(event.target.id +"Block");
      objname.innerText = event.target.value;
    }

    changeWidth(event) {
      let objname = document.getElementById(event.target.id +"Block");
      const canvas = document.querySelectorAll('.cert-image')[0];
      if ((parseInt(objname.style.left) + parseInt(event.target.value)) < parseInt(canvas.style.width)) {
        objname.style.width = event.target.value + "px";
      } else {
        event.target.value = parseInt(canvas.style.width) - parseInt(objname.style.left);
      }
    }

    showText(event) {
      let objname = document.getElementById(event.target.id +"Block");
      if (event.target.checked) {
        objname.hidden  = false;
      } else {
        objname.hidden = true;
      }
    }
}

let dx,dy; //coordinats before dragstart
let flagLG = 0; // Small ScreeSize flag
let flagSM = 0; // Large ScreeSize flag
let flagScale = 0;
let window_scale = 0; //current window

function getParameters(scale) {
  let parameters = [];
  const blocks = Array.from(document.getElementById('certificate').getElementsByClassName('text-block'));

  blocks.forEach(element => {
    let element_text = element.innerText;

    ["usernameBlock","descriptionBlock","dateBlock"].forEach(item => {
      if (element.id == item) element_text = "";
    });

    const parameter = { name: element.id,
                        x: scaling(element.style.left, scale),
                        y: scaling(element.style.top, scale),
                        fontsize: scaling(element.style.fontSize, scale),
                        width: scaling(element.style.width, scale),
                        hidden: element.hidden,
                        text: element_text,
                        fontfamily: element.style.fontFamily };

    parameters.push(parameter);
  });

  return parameters;
}

function scaling(value, scale){
  return Math.round(value.slice(0,-2) * scale) + "px";
}

let observer = new ResizeObserver(function(objects) {
  let resizeTimer;
  for (let object of objects) {
    clearTimeout(resizeTimer);
    //delay before change size block when mousebutton will release
    resizeTimer = setTimeout(function() {
      if (parseInt(object.target.style.width)+parseInt(object.target.style.left) > parseInt(canvas.style.width)) {
        object.target.style.width = (parseInt(canvas.style.width) - parseInt(object.target.style.left)) + "px";
      }
    }, 500);

    document.getElementsByName(object.target.getAttribute('name')+"Width")[0].value = object.contentRect.width+2;
  }
});

function addCloseEvent(event){
  const name_block = event.target.closest(".card").querySelectorAll(".form-check-input")[0].id;
  let block = document.getElementById(name_block+"Block");

  if (block) {
    observer.unobserve(block);
    document.getElementById(name_block+"Block").remove();
  }

  event.target.closest(".card").remove();
}

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}

function createFile(fileData, imageInput) {
  const parameters = JSON.stringify({file : fileData});
  const token = document.getElementsByName("csrf-token")[0].content;

  fetch('/api/v1/files.json', {
    body: parameters,
    method: 'POST',
    credentials: "include",
    headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
    },
  })
  .then(response => {
    return response.json();
  })
  .then(data => {
    imageInput.value = data["content_id"];
  });
}

function loadImageOnPage(file) {
  const reader = new FileReader();
  const image = document.getElementById("certificate_image");

  reader.onload = function(event) {
    image.src = event.target.result;
  };

  reader.readAsDataURL(file);

  return true;
}

function askToResumeUpload(previousUploads) {
  if (previousUploads.length === 0) return null;

  const text = "You tried to upload this file previously at these times:\n\n";

  previousUploads.forEach((previousUpload, index) => {
    text += "[" + index + "] " + previousUpload.creationTime + "\n";
  });
  text += "\nEnter the corresponding number to resume an upload or press Cancel to start a new upload";

  var answer = prompt(text);
  var index = parseInt(answer, 10);

  if (!isNaN(index) && previousUploads[index]) {
    return previousUploads[index];
  }
}

function scaleElements(scale){
  const blocks = Array.from(document.getElementById('certificate').getElementsByClassName('text-block'));
  blocks.forEach(element =>
    {
      element.style.width = scaling(element.style.width, scale);
      element.style.left = scaling(element.style.left, scale);
      element.style.top = scaling(element.style.top, scale);
      element.style.fontSize = scaling(element.style.fontSize, scale);
      document.querySelector('.font-size#' + element.id.slice(0,-5)).value = element.style.fontSize.slice(0,-2);
    }
  );
}
