import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "image" ]

  static values = {
    urlUpdate: String
  }

  connect(){}

  changeImage(event) {
    const file = event.target.files[0]
    loadImageOnPage(file, this.imageTarget)
  }
}

function loadImageOnPage(file, image){
  const reader = new FileReader()
  if (image) {
    reader.onload = function(event) {
      image.src = event.target.result
    }
    reader.readAsDataURL(file)
    return true
  } else {
    return false
  }  
}
