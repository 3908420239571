import React from 'react'
import noavatar from '../images/noavatar.jpg'

const UserAvatar = (props) => {
  const { user } = props
  return (
    <div className={"msg_left" + props.containerClasses}>
      <img src={user.avatar_url || noavatar} className="user_avatar" alt="" />
    </div>
  )
}

export default UserAvatar