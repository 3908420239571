import { Controller } from "@hotwired/stimulus"
import { toastrNotification } from '../helpers/Toastr'

export default class extends Controller {

  static values = {
  }

  connect() {
    document.addEventListener("DOMContentLoaded", function () {
      document.getElementById("copy-course-link").addEventListener("click", function (event) {
        event.preventDefault();
        const courseLink = document.getElementById("copy-course-link").href;
        navigator.clipboard.writeText(courseLink).then(function () {
          toastrNotification({ type: 'success', title: window.t('course.copy_link') })
        }, function (err) {
          toastrNotification({ type: 'error', title: err })
        });
      });
    });
  }


}



