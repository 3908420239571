import React, { useState, useEffect } from 'react'
import SurveyQuestion from './SurveyQuestion'
import Question from '../models/Question'
import ListController from '../controllers/ListController'
import LectionForm from './LectionForm'
import { client } from '../../Client'

import '../styles.css'

export default function SurveyBuilder({ survey, lection, course, updateLectionTitle, updateLectionAssessment, pathname }) {
  const surveyQuestions = survey.questions.map(question => {
    return new Question({
      id: question.id,
      text: question.name,
      type: question.qtype,
      options: question.options,
      correct_answers: question.correct_answers,
      required: question.required,
      persisted: true
    })
  })
  const [questions, setQuestions] = useState(surveyQuestions)
  const [sorted, setSorted] = useState(false)
  const listController = new ListController(questions, setQuestions)

  function handleQuestionsSort(direction, i) {
    if (direction === 'up') {
      listController.moveUp(i)
    } else {
      listController.moveDown(i)
    }
    setSorted(true)
  }

  useEffect(() => {
    if (sorted) {
      const questionIds = questions.map(question => question.id)
      client.sortSurveyQuestions(survey.id, questionIds)
      setSorted(false)
    }
  })

  return (
    <div className="py-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card bg-transparent border rounded-3 mb-5">
              <div className="card-header bg-transparent border-bottom d-flex justify-content-between align-items-center">
                <h5 className="">{window.t('course.edit_survey')}</h5>
                <div className="d-flex">
                  <div className="dropdown me-3">
                    <a className="btn btn-primary-soft" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href={Routes.tutor_course_lection_path(locale, course.slug, survey.lection_id)} target="_blank" >
                        <i className="fas fa-external-link-alt"></i> <span className="mg-l-5">{window.t('course.preview')}</span>
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href={Routes.report_tutor_course_survey_path(locale, course.slug, survey.id)} >
                        <i className="far fa-eye"></i> <span className="mg-l-5">{window.t('survey.view_answers')}</span>
                      </a>
                      <a className="dropdown-item" href={Routes.export_answers_tutor_course_survey_path(locale, course.slug, survey.id, { format: 'xlsx' })} >
                        <i className="fas fa-download"></i> <span className="mg-l-5">{window.t('survey.export_answers')}</span>
                      </a>
                    </div>
                  </div>
                  {pathname.includes("/report")
                    ?
                    <a className="btn btn-primary-soft d-flex align-items-center" href={Routes.edit_tutor_course_survey_path(locale, course.slug, survey.id)} >
                      {window.t("done")}
                    </a>
                    :
                    <a className="btn btn-primary-soft d-flex align-items-center" href={Routes.tutor_course_curriculum_path(locale, course.slug)} >
                      {window.t("done")}
                    </a>
                  }
                </div>
              </div>
              <div className="card-body">
                <LectionForm
                  lection={lection}
                  course={course}
                  updateLectionTitle={updateLectionTitle}
                  updateLectionAssessment={updateLectionAssessment}
                />

                <div className="d-md-flex d-block align-items-center justify-content-between mg-b-10">
                  <div className="col">
                    {questions.length > 0 ?
                      <div>
                        {questions.map((question, i) => (
                          <SurveyQuestion
                            key={question.id}
                            position={i}
                            survey={survey}
                            question={question}
                            setQuestion={question => listController.set(i, question)}
                            removeQuestion={() => listController.remove(i)}
                            moveQuestionUp={() => handleQuestionsSort('up', i)}
                            moveQuestionDown={() => handleQuestionsSort('down', i)}
                          />
                        ))}
                        <button onClick={() => listController.add(new Question())} className="btn btn-white border btn-sm mt-3">
                          <i className="fas fa-plus icon" />
                          {window.t('survey.add_question')}
                        </button>
                      </div> :
                      <div>
                        <div>
                          {window.t('survey.no_questions_yet')}
                        </div>
                        <button onClick={() => listController.add(new Question())} className="btn btn-white border btn-sm mt-3">
                          <i className="fas fa-plus icon" />
                          {window.t('survey.add_question')}
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
