import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  updateClass(event) {
    const channelsList = document.getElementById('channels-list');
    const activeLinks = channelsList.querySelectorAll('.nav-link.active');

    activeLinks.forEach(link => {
      link.classList.remove('active');
    });

    const unreadMessagesBadge = event.currentTarget.querySelector('.badge');
    if (unreadMessagesBadge) event.currentTarget.removeChild(unreadMessagesBadge);

    event.currentTarget.classList.add('active');
  }
}