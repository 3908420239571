import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import ImageEditor from "@uppy/image-editor"
import Tus from "@uppy/tus"
import Russian from '@uppy/locales/lib/ru_RU'
import { toastrNotification } from '../helpers/Toastr'

import '@uppy/image-editor/dist/style.css'

export default class extends Controller {

  static targets = ["image", "form"]

  static values = {
    urlUpdate: String,
    noImageUrl: String,
    maxVideoSize: Number,
  }

  changeImage(event) {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', 'video/*']
      },
      autoProceed: false,
      locale: Russian
    })
    uppy.use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5 * 1024 * 1024 })
    uppy.use(Dashboard, {
      proudlyDisplayPoweredByUppy: false,
      autoOpenFileEditor: true,
      hideCancelButton: true
    })
    uppy.use(ImageEditor, {
      target: Dashboard,
      quality: 1,
      cropperOptions: {
        viewMode: 1,
        background: true,
        autoCropArea: 0.8,
        responsive: true,
        aspectRatio: 4 / 3,
        croppedCanvasOptions: { width: 640, height: 480 },
      },
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
    })

    uppy.on('upload-success', (file, response) => {
      let fileData = {
        title: file.name,
        file_orig_name: file.name,
        file_url: response.uploadURL,
        file_size: file.size,
        file_type: file.meta.type
      }
      if (file.size < this.maxVideoSizeValue * 1000000) {
        if (isFileImage(file)) {
          createImageFile(fileData)
          this.imageTarget.src = file.preview
        }
        else {
          createVideoFile(fileData)
          document.getElementById('transcoding').hidden = false
        }
      }
      else {
        toastrNotification({ type: 'error', title: window.t('news_items.video_too_big') })
      }
      uppy.getPlugin('Dashboard').closeModal();
    });

    uppy.getPlugin('Dashboard').openModal();
  }
}

function createImageFile(fileData) {
  const parameters = JSON.stringify({ file: fileData });
  const token = document.getElementsByName("csrf-token")[0].content;

  fetch('/api/v1/files.json', {
    body: parameters,
    method: 'POST',
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "X-CSRF-Token": token
    },
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      document.getElementById("image").value = data["content_id"]
      document.getElementById("video").value = null
    })
    .catch((e) => {
      toastrNotification({ type: 'error', title: window.t('news_items.error_image_loading') })
    })
}

function createVideoFile(fileData) {
  const parameters = JSON.stringify({ file: fileData });
  const token = document.getElementsByName("csrf-token")[0].content;

  fetch('/api/v1/files.json', {
    body: parameters,
    method: 'POST',
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "X-CSRF-Token": token
    },
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      document.getElementById("video").value = data["content_id"]
      document.getElementById("image").value = null
      toastrNotification({ type: 'success', title: window.t('news_items.video_transcoding') })
      return true
    })
    .catch((e) => {
      toastrNotification({ type: 'error', title: window.t('news_items.error_video_loading') })
      return false
    })
}

function isFileImage(file) {
  return file && file['type'].split('/')[0] === 'image';
}