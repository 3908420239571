import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'
import { client } from '../Client'

const NoteModal = ({ course, license, currentLection, addNote, videoPlayerRef }) => {
  const [show, setShow] = useState(false)
  const [playerTime, setPlayerTime] = useState(0)

  const handleClose = (values) => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.play()
    }
    setShow(false)
    values.title = ""

    document.getElementById('addNoteModal').classList.remove('shadow')
  }

  const handleShow = () => {
    if (videoPlayerRef.current) {
      setPlayerTime(videoPlayerRef.current.getCurrentTime())
      videoPlayerRef.current.pause()
    }
    setShow(true)
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(window.t('form.required')),
  })

  function showNoteModal(e) {
    e.preventDefault()
    document.getElementById('addNoteModal').classList.add('shadow')
  }

  return (

    <>
      {/* <div id="addNoteModal" className={show ? 'shadow': ''} > */}
      <div id="addNoteModal" >
        {/* <Button variant="warning" size="sm" onClick={handleShow}>
        <i className="bi fa-fw bi-pencil-square me-2"></i> { window.t('course_viewer.add_note') }
      </Button> */}
        <div className="confirm_modal">
          <div className="modal_header">
            <div className="container">
              <div className="container" onClick={handleClose}>
                <svg id="close" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M14.75 14.75L1.25 1.25" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M14.75 1.25L1.25 14.75" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          <div className="container user_form">
            <div className="modal_body d-flex flex-column">
              {/* <h3 className="confirm-title"></h3>
            <p className="confirm-text"></p> */}
              {/* <div className="d-flex justify-content-center"> */}
              <Formik
                initialValues={{ title: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  let data = values
                  data['lection_id'] = currentLection.id
                  data['time_seconds'] = Math.round(videoPlayerRef.current.getCurrentTime())
                  client.createCourseNote(license.id, data)
                    .then(note => {
                      if (note.id) {
                        addNote(note)
                        toastrNotification({ type: 'success', title: window.t('created') })
                        handleClose(values)
                      } else {
                        toastrNotification({ type: 'error', title: window.t('not_created') })
                      }
                      setSubmitting(false)
                    })
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isValid,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className='user_form d-flex flex-column '>
                    <div className="d-flex flex-column">
                      <div className="form_group d-flex flex-column">
                        <label className="d-flex flex-column">{window.t('course_viewer.type_your_note')}</label>
                        <input
                          type="text"
                          name="title"
                          className={(errors.title && touched.title ? 'is-invalid' : '')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          placeholder={window.t('course_viewer.type_your_note')}
                        />
                        {errors.title && touched.title &&
                          <div className="invalid-feedback">{errors.title}</div>
                        }
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mt-5">
                      <Button variant="secondary" onClick={handleClose}>
                        {window.t('cancel')}
                      </Button>
                      <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
                        {window.t('save')}
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
              {/* </div> */}
            </div>
          </div>

        </div>

        {/* <Modal show={show} onHide={handleClose} className='SSS'>
        <Modal.Header closeButton>
          <Modal.Title>{ window.t('course_viewer.add_new_note') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ title: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              let data = values
              data['lection_id'] = currentLection.id
              data['time_seconds'] = Math.round(playerTime)
              client.createCourseNote(license.id, data)
              .then(note => {
                if (note.id) {
                  addNote(note)
                  toastrNotification({ type: 'success', title: window.t('created') })
                  handleClose()
                } else {
                  toastrNotification({ type: 'error', title:  window.t('not_created') })
                }
                setSubmitting(false)
              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="form-label">{ window.t('course_viewer.type_your_note') }</label>
                    <input
                      type="text"
                      name="title"
                      className={"form-control " + (errors.title && touched.title ? 'is-invalid' : '')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      placeholder={ window.t('course_viewer.type_your_note') }
                    />
                    {errors.title && touched.title &&
                      <div className="invalid-feedback">{errors.title}</div>
                    }
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-5">
                  <Button variant="secondary" onClick={handleClose}>
                    { window.t('cancel') }
                  </Button>
                  <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
                    { window.t('save') }
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal> */}
      </div>
    </>
  )
}

export default NoteModal