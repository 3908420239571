import React from 'react'

const ContentText = ({ lection }) => {
  return (
    <div className="player lecture-content player-text">
      <div className="card rounded-3">
        <div className="card-title rounded-3 text-center fw-bold pt-2">{lection.title}</div>
        <div className="card-body rounded-3 pt-0" dangerouslySetInnerHTML={{ __html: lection.description }}></div>
      </div>
    </div>
  )
}

export default ContentText