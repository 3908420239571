import React from 'react'
import { client } from './../../Client'
import Uppy from '@uppy/core'
import Russian from '@uppy/locales/lib/ru_RU'
import Tus from '@uppy/tus'
import { DashboardModal } from '@uppy/react'
import { Dropdown } from 'react-bootstrap'
import { toastrNotification } from '../../../helpers/Toastr'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

class FilesUploader extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      modalOpen: false,
      uploadedFiles: 0
    }

    const locale = window.locale == "ru" ? Russian : 'en_EN'

    this.uppy = new Uppy({
      restrictions: { maxNumberOfFiles: 10 },
      autoProceed: true,
      locale: locale
    }).use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5*1024*1024 })

    this.uppy.on('upload-success', (file, response) => {
      const lectionData = { content_type: 'file' }
      const fileData =  { title: file.name,
                          file_orig_name: file.name,
                          file_url: response.uploadURL,
                          file_size: file.size,
                          file_type: file.meta.type }

      client.createCourseFileLection(this.props.course.id, this.props.section.id, lectionData, fileData)
      .then((response) => {
        this.setState({ uploadedFiles: this.state.uploadedFiles + 1 })
      })
    })

    this.uppy.on('file-removed', (file, reason) => {
      if (file.uploadURL && reason === 'removed-by-user') {
        client.deleteFile(file.uploadURL)
      }
    })

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentWillUnmount() {
    this.uppy.close()
  }

  handleOpen () {
    this.setState({ modalOpen: true })
  }

  handleClose () {
    this.setState({
      modalOpen: false
    }, () => {
      if (this.state.uploadedFiles > 0) {
        Turbo.visit(window.location)
      }
    })
  }

  render () {
    return (
      <>
        <Dropdown.Item
          as="button"
          onClick={() => this.handleOpen()}
        >
          <i className="fas fa-upload"></i> { window.t('course.add_files') }
        </Dropdown.Item>
        <DashboardModal
          uppy={this.uppy}
          closeModalOnClickOutside
          open={this.state.modalOpen}
          onRequestClose={this.handleClose}
          target={document.body}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          showRemoveButtonAfterComplete={false}
          doneButtonHandler={this.handleClose}
        />
      </>
    )
  }
}

export default FilesUploader