import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["startAt", "triggerContent", "triggerInterval"]

  static values = {
    urlUpdate: String
  }

  connect() {
  }

  changeBlock(event) {
    var startAt = this.startAtTarget
    var triggerContent = this.triggerContentTarget
    var triggerInterval = this.triggerIntervalTarget
    switch (event.target.value) {
      case "test_trigger":
        triggerContent.hidden = false
        startAt.hidden = true
        triggerInterval.hidden = true
        break
      case "interval_trigger":
        triggerInterval.hidden = false
        startAt.hidden = true
        triggerContent.hidden = true

        break
      case "time_trigger":
        startAt.hidden = false
        triggerInterval.hidden = true
        triggerContent.hidden = true
        break
      default:
      // this.urlUpdateValue = "compositeLayout"
    }
  }
}
