import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    const curCountry = this.inputTarget.getAttribute('data-country')
    const inputName = this.inputTarget.getAttribute('data-input')
    const scriptUrl = this.inputTarget.getAttribute('data-script')
    const country = curCountry ? curCountry : 'ru'

    window.intlTelInput(this.inputTarget, {
      preferredCountries: ['ru', 'kz', 'by'],
      autoPlaceholder: 'aggressive',
      formatOnDisplay: true,
      strictMode: false,
      separateDialCode: true,
      initialCountry: country,
      hiddenInput: inputName,
      utilsScript: scriptUrl
    })
  }
}