import Carousel from "@stimulus-components/carousel";

export default class extends Carousel {
  static targets = ["wrapper", "prevBtn", "nextBtn"]
  static values = {
    isDesktop: { type: Boolean, default: false },
    isStoriesSlider: { type: Boolean, default: false },
    desktopPerColumn: { type: Number, default: 2 }
  }

  connect(){
    super.connect()
    this.isSlideAllowed = false

    if(!this.isDesktopValue) {
      this.wrapperTarget.classList.remove("swiper-align")
    }
  }

  showAll() {
    this.#toggleEnabled()
  }

  #toggleEnabled() {
    if(this.wrapperTarget.classList.contains("swiper-wrapper")) {
      this.wrapperTarget.classList.add("show-all")
      this.wrapperTarget.classList.remove("swiper-wrapper")
      super.disconnect() 
    }
    else {
      this.wrapperTarget.classList.remove("show-all")
      this.wrapperTarget.classList.add("swiper-wrapper")
      super.connect()
    }

    this.#toggleNavigationButtonsDisplay()
  }

  #toggleNavigationButtonsDisplay() {
    this.prevBtnTarget.classList.toggle("disable-btn")
    this.nextBtnTarget.classList.toggle("disable-btn")
  }

  get defaultOptions() {
    if(this.isDesktopValue) {
      return ({
        slidesPerColumn: this.desktopPerColumnValue,
        spaceBetween: 10,
        slidesPerGroup: 2,
        slidesPerGroupSkip:1,
        navigation: {
          nextEl: this.nextBtnTarget,
          prevEl: this.prevBtnTarget,
        },
        breakpoints: {
          500: {
            slidesPerView: 'auto',
            spaceBetween: 8,
          },
          5000: {
            spaceBetween: 12,
            slidesPerView: 'auto',
          },
        }
      })
    }
    else if(this.isStoriesSliderValue) {
      return ({
        allowTouchMove: true,
        spaceBetween: 12,
        slidesPerView: 'auto',
      })
    }
    else {
      return ({
        spaceBetween: 12,
        slidesPerColumn: this.desktopPerColumnValue,
        slidesPerView: "auto",
      })
    }
  }
}
