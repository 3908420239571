import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    successUrl: String,
    failUrl: String,
    invoiceUrl: String,
  }

  connect() {
    setInterval(() => {
      this.getInvoiceStatus()
    }, 3000);
  }

  getInvoiceStatus() {
    fetch(this.invoiceUrlValue)
    .then(response => { return response.json() })
    .then(invoice => {
      console.log(invoice.status)
      if (invoice.status === 'paid') {
        window.location.href = this.successUrlValue
      }
      if (invoice.status === 'unpaid') {
        window.location.href = this.failUrlValue
      }
    });
  } 
}