import React from 'react'
import ReactDOM from 'react-dom'
import CourseViewerMobile from './CourseViewerMobile'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

document.addEventListener('turbo:load', () => {
   const viewerContainer = document.getElementById('courseViewerMobile')

   if (viewerContainer) {
     const course = JSON.parse(viewerContainer.dataset.course),
           trackers = JSON.parse(viewerContainer.dataset.trackers),
           returnUrl = viewerContainer.dataset.returnUrl,
           license = JSON.parse(viewerContainer.dataset.license),
           notes = JSON.parse(viewerContainer.dataset.notes),
           conversation = JSON.parse(viewerContainer.dataset.conversation)

     ReactDOM.render(
       <Router>
         <Switch>
            <Route
              path="/:locale/tutor/courses/:courseSlug/lections/:lectionId"
              render={
                       props => <CourseViewerMobile
                                 {...props}
                                 course={course}
                                 trackers={trackers}
                                 preview={true}
                                 returnUrl={returnUrl}
                                 license={license}
                                 notes={notes}
                                 conversation={conversation}
                                />
                     }
            />
            <Route
              path="/:locale/pupil/courses/:courseSlug/lecture/:lectionId"
              render={
                       props => <CourseViewerMobile
                                  preview={false}
                                  {...props}
                                  course={course}
                                  trackers={trackers}
                                  returnUrl={returnUrl}
                                  license={license}
                                  notes={notes}
                                  conversation={conversation}

                                />
                     }
            />
         </Switch>
       </Router>,
       viewerContainer,
     );
   }
})
