import React from 'react'
import ReactDOM from 'react-dom'
import Invitations from './Invitations'

document.addEventListener('turbo:load', () => {
  const invitationsContainer = document.getElementById('courseInvitations')

  if (invitationsContainer) {
    const course = JSON.parse(invitationsContainer.dataset.course)

    ReactDOM.render(
      <Invitations course={course} />,
      invitationsContainer,
    );
  }

})