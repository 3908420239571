import { Controller } from "@hotwired/stimulus"

export default class extends Controller {


  connect() {
    this.element.addEventListener('turbo:submit-end', (event) => {
        if (event.detail.success) {
          var myModalEl = document.getElementById('modal2');
          var modal = bootstrap.Modal.getInstance(myModalEl)
          $('.modal-backdrop').remove();
          modal.hide();
          // $("#modal2").hide();
          // $("body").removeClass('modal-open')
        }
    });
  }
}
