import React from 'react'
import { client } from '../Client'
import { Formik, Field } from 'formik'
import TrixEditor from '../../helpers/TrixEditor'
import HomeworkSubmissionFilesUploader from './HomeworkSubmissionFilesUploader'
import FilePreview from '../../helpers/FilePreview'
import { toastrNotification } from '../../helpers/Toastr'

const HomeworkSubmissionForm = ({ homeworkId, homeworkInstanceId, submission, closeForm }) => {

  const renderPreviewFileLink = (file) => {
    if (file.is_video) {
      return (<a href={Routes.tutor_video_path(window.locale, file.content_id)} data-modal="true">{file.name}</a>)
    } else {
      return (<FilePreview file={file} />)
    }
  }

  const removeAttachment = (e, attachment) => {
    e.preventDefault()
    //client.deleteHomeworkAttachment(attachment.id, homework.id)
    //.then(() => {
    //  setAttachments(attachments.filter(at => at.id !== attachment.id))
    //})
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <h3 className="card-header">
              {window.t("submission.submission_at")} {submission.created_at_s}
            </h3>
            <div className="row">
              <div className="col-12">
                {submission &&
                  <Formik
                    initialValues={{ description: submission.description || "" }}
                    onSubmit={(values, { setSubmitting }) => {
                      client.updateHomeworkSubmission(homeworkId, homeworkInstanceId, submission.id, values)
                        .then(submission => {
                          if (submission.id) {
                            toastrNotification({ type: 'success', title: window.t('updated') })
                            Turbo.visit(window.location)
                          } else {
                            toastrNotification({ type: 'error', title: window.t('not_updated') })
                          }
                          setSubmitting(false)
                        })
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form">
                          <div className="col-12">
                            <div className="form-group">
                              <label>{window.t('homework_submission.description')}</label>
                              <TrixEditor
                                value={values.description}
                                onChange={e => setFieldValue('description', e)}
                              />
                            </div>
                          </div>

                          {submission.attachments.length > 0 &&
                            <div className="col-12 mb-3">
                              {submission.attachments.map(attachment => (
                                <div key={attachment.id}>
                                  {renderPreviewFileLink(attachment.file)}
                                  <button type="button" className="btn btn-icon mg-l-5 btn-xs" onClick={e => removeAttachment(e, attachment)}>
                                    <i className="fas fa-times"></i>
                                  </button>
                                </div>
                              ))}
                            </div>
                          }

                          <div className="col-12 mb-4">
                            <HomeworkSubmissionFilesUploader
                              homeworkId={homeworkId}
                              homeworkInstanceId={homeworkInstanceId}
                              submission={submission}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex justify-content-between">
                              <button type="button" className="btn btn-link" disabled={isSubmitting} onClick={closeForm}>
                                {window.t('cancel')}
                              </button>
                              <button type="submit" className="btn btn-primary bnt-lg" disabled={isSubmitting || !isValid}>
                                {window.t('save')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeworkSubmissionForm