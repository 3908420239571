import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  show(event) {
    event.preventDefault();
    const href = this.element.getAttribute('href')
    const modalEl = document.getElementById('deleteConfirmationModal');

    if (modalEl && href) {
      const buttonEl = modalEl.querySelector('form.button_to');
      const confirmTextEl = modalEl.querySelector('.confirm-text');
      const confirmTitleEl = modalEl.querySelector('.confirm-title');

      buttonEl.setAttribute('action', href);
      confirmTextEl.innerHTML = this.element.dataset.confirmText;
      confirmTitleEl.innerHTML = this.element.dataset.confirmTitle;

      const modal = new bootstrap.Modal(modalEl, {});
      modal.show();
    }
  }

  help(event) {
    event.preventDefault();
    const modalEl = document.getElementById('helpModal');
    if (modalEl) {
      const modalTitle = modalEl.querySelector('.modal-title');
      const modalHelp = modalEl.querySelector('.help-text');
      modalTitle.innerHTML = event.target.dataset.helpTitle;
      modalHelp.innerHTML = event.target.dataset.helpMessage;

      const modal = new bootstrap.Modal(modalEl, {});
      modal.show();
    }
  }
}