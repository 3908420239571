import React from 'react'

const FinishCourseMessage = (props) => {
  return (
    <div className="">
      <div className="col-12">
        <div className="finish-course-message text-center">
          <div className="jumbotron">
            <h1 className="display-4">{ window.t('course_viewer.finish_course_title') }</h1>
            <p className="lead">{ window.t('course_viewer.finish_course_text') } `{props.course.title}`.</p>
              <a href={props.course.certificate_template_id !=null? Routes.tutor_certificate_template_path(locale, props.course.certificate_template_id, {format: 'pdf', license_id: props.license.id}): "#" }>{props.course.certificate_template_id !=null? window.t('course_viewer.finish_course_certificate'):"" }</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinishCourseMessage
