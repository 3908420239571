import { Controller } from "@hotwired/stimulus"
import { func } from "prop-types";

export default class extends Controller {

  // static targets = ['image', 'imageInput']

  static values = {
    urlUpdate: String
  }

  connect() {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    window.selectedTime = { hour: parseInt(currentHour), minute: parseInt(currentMinute) + 10 };
  }

  handleClick(event) {
    var currentHour, currentMinute;
    const closestTd = event.target.closest('td');
    if (event.target.classList.contains('fc-title') || event.target.classList.contains('fc-content')) return;

    if (!event.target.classList.contains('fc-time') && !event.target.classList.contains('fc-title')) {
      if (closestTd) {
        currentDay = closestTd.dataset.day;
        const currentDate = new Date();
        const selectedDate = new Date(currentDay);
        selectedDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        if (currentDate.getTime() === selectedDate.getTime()) {
          currentHour = new Date().getHours();
          currentMinute = new Date().getMinutes();
        }
        else {
          currentHour = 11;
          currentMinute = 30;
        }
        if (currentDate <= selectedDate) {
          this.requestTurboStream(currentDay, currentHour, currentMinute);
        }
      } else {
        console.log('No td found under cursor');
      }
    }
  }

  requestTurboStream(current_day, hour, minute) {
    const urlUpdate = this.urlUpdateValue
    const url = `${urlUpdate}?current_day=${current_day}&hour=${hour}&minute=${minute}`; // Update with your actual controller and action
    fetch(url, {
      headers: {
        // 'Accept': 'text/vnd.turbo-stream.html'
      }
    })
      .then(response => response.text())
      .then(html => {
        this.showModal(html);
      })
      .catch(error => console.error('Error:', error));
  }

  showModal(html) {
    let modal = document.getElementById('meetingModal');
    if (!modal) {
      modal = document.createElement('div');
      modal.id = 'meetingModal';
      modal.className = 'modal fade';
      modal.tabIndex = -1;
      modal.setAttribute('role', 'dialog');
      modal.innerHTML = `
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              ${html}
            </div>
          </div>
        </div>
      `;
      document.body.appendChild(modal);
    } else {
      modal.querySelector('.modal-body').innerHTML = html;
    }

    // Show the modal using Bootstrap's modal method
    $(modal).modal('show');
  }

  showTimePicker(currentDay) {
    let modal = document.getElementById('timePickerModal');
    if (!modal) {
      modal = document.createElement('div');
      modal.id = 'timePickerModal';
      modal.className = 'modal fade';
      modal.tabIndex = -1;
      modal.setAttribute('role', 'dialog');
      const formattedDate = new Date(currentDay).toLocaleDateString(navigator.language, {
        year: 'numeric', month: 'long', day: 'numeric'
      });

      modal.innerHTML = `
        <div class="modal-dialog" role="document">
          <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">${formattedDate}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <h5>Выберите время онлайн-встречи </h5>
            <div class="row">
          <div class="col-8 hours" style="max-height: 600px; max-width: 300px; overflow-y: auto;">
            <h6>Hour</h6>
            <div class="row">
            <div class="col-6">

            ${Array.from({ length: 12 }, (_, i) => `
          <div class="col">
            <button class="time-btn btn ${window.selectedTime['hour'] === i ? 'btn-white' : 'btn-success'} btn-block my-0 py-0 border" style="width: 100%;" data-hour="${i}">${i}</button>
          </div>
            `).join('')}
          </div>
            <div class="col-6">

            ${Array.from({ length: 12 }, (_, i) => `
          <div class="col">
            <button class="time-btn btn ${window.selectedTime['hour'] === i + 12 ? 'btn-white' : 'btn-success'} btn-block my-0 py-0 border" style="width: 100%;" data-hour="${i + 12}">${i + 12}</button>
          </div>
            `).join('')}
          </div>
          </div>
          </div>
          <div class="col-4">
            <h6>Minute</h6>
            ${Array.from({ length: 6 }, (_, i) => `
          <div class="row mb-1">
            <div class="col">
          <button class="time-btn btn ${(window.selectedTime['minute'] < (i * 10) && window.selectedTime['minute'] > ((i - 1) * 10)) ? 'btn-white' : 'btn-success'} btn-block border" style="width: 100%;" data-minute="${i * 10}">${i * 10}</button>
            </div>
          </div>
            `).join('')}
          </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
          <button type="button" class="confirm btn btn-primary">Выбрать</button>
        </div>
          </div>
        </div>
      `;
      document.body.appendChild(modal);
    }
    modal.querySelector('.confirm').addEventListener('click', () => {
      this.confirmTime();
    });

    modal.querySelectorAll('.time-btn').forEach(button => {
      button.addEventListener('click', (event) => {
        if (button.getAttribute('data-hour') === null) {
          this.selectTime(event, button.getAttribute('data-minute'), 'minute');
        }
        else {
          this.selectTime(event, button.getAttribute('data-hour'), 'hour');
        }
      });
    });
    $(modal).modal('show');
  }

  selectTime(event, value, type) {
    this.switchOffCurrentButton(type);
    window.selectedTime[type] = value;
    event.target.classList.remove('btn-success');
    event.target.classList.add('btn-white');

  }

  switchOffCurrentButton(type) {
    const value = type === 'hour' ? window.selectedTime['hour'] : this.roundNumber(parseInt(window.selectedTime['minute']));
    const buttons = document.querySelectorAll('button');
    buttons.forEach(button => {
      if (parseInt(button.textContent.trim()) === parseInt(value)) {
        button.classList.remove('btn-white');
        button.classList.add('btn-success');
      }
    });
  }

  confirmTime() {
    const { hour, minute } = window.selectedTime;
    if (hour !== null && minute !== null) {
      $('#timePickerModal').modal('hide');
      this.requestTurboStream(currentDay, hour, minute);
    } else {
      alert('Please select both hour and minute.');
    }
  }
  roundNumber(num) {
    return Math.ceil(num / 10) * 10;
  }
}

var currentDay = null;



