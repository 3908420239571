import React, { useEffect, useState } from 'react'
import { client } from '../Client'
import Loader from '../../helpers/Loader'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'

export default function ContentSurvey({ lection, markLectionCompleted, preview, license }) {
  const [testResult, setTestResult] = useState("start")
  const [completed, setCompleted] = useState(false)
  const [answers, setAnswers] = useState([])
  const [survey, setSurvey] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    if (preview) {
      getSurvey().then(() => setLoading(false))
    } else {
      Promise.all([getSurvey(), getAnswers(), getTestResult()]).then(() => {
        setLoading(false)
      })
    }
  }, [])

  const getSurvey = () => {
    return client.getSurvey(lection.content.id)
      .then(survey => {
        if (survey.id) {
          setSurvey(survey)
        }
      })
  }

  const getAnswers = () => {
    return client.getSurveyUserAnswers(lection.content.id, license.id)
      .then(answers => {
        if (answers.length > 0) {
          setCompleted(true)
          setAnswers(answers)
        }
      })
  }

  const getTestResult = () => {
    return client.getSurveyTestResult(lection.content.id, license.id)
      .then(testResult => {
        setTestResult(testResult.result)
      })
  }

  function getInitialValues() {
    let values = {}
    survey.questions.forEach((question, i) => {
      values[`q${question.id}`] = ''
    })
    return values
  }

  function getValidationSchema() {
    let fieldsToValidate = {}
    survey.questions.filter(question => question.required).forEach((question, i) => {
      if (question.qtype === 'options_pick_many') {
        fieldsToValidate[`q${question.id}`] = Yup.array().required(window.t('form.required'))
      } else {
        fieldsToValidate[`q${question.id}`] = Yup.string().required(window.t('form.required'))
      }
    })
    return fieldsToValidate
  }

  return (
    <div className="player lecture-content player-text">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="card overflow-auto">
              <div className="card-body rounded-3 p-0 m-1">
                {loading && <Loader />}

                {!loading && !survey && !completed &&
                  <div className='row'>
                    <div className='col-12 text-center alert alert-danger'>
                      Unable to find survey
                    </div>
                  </div>
                }

                {!loading && completed && survey &&
                  <div className='row'>
                    <div className='col-12'>
                      <h5 className="card-title">{survey.name}</h5>
                      {answers.map(answer => (
                        <div key={answer.id} className="form-group col-12 my-3">
                          <label className="mb-1">{answer.question.name}</label>
                          <p>{answer.data}</p>
                          {lection.assessment ?
                            <b>{answer.correct ? window.t('survey.correct') : window.t('survey.wrong')}</b>
                            :
                            ""
                          }
                        </div>
                      ))
                      }
                    </div>
                    {lection.assessment ?
                      <b className='mt-2'>{window.t('survey.result')}: {testResult}</b>
                      :
                      <b className='mt-2'>{window.t('survey.result')}: {window.t('survey.completed')}</b>
                    }
                  </div>
                }

                {survey && !loading && !completed &&
                  <div className=''>
                    <h5 className="card-title">{survey.name}</h5>
                    <Formik
                      initialValues={getInitialValues()}
                      validationSchema={Yup.object().shape(getValidationSchema())}
                      onSubmit={(values, { setSubmitting }) => {
                        client.createSurveyAnswers(survey.id, license.id, values)
                          .then(response => {
                            if (response.success) {
                              markLectionCompleted(lection)
                              getTestResult()
                              client.getSurveyUserAnswers(lection.content.id, license.id)
                                .then(answers => {
                                  if (answers.length > 0) {
                                    setCompleted(true)
                                    setAnswers(answers)
                                    toastrNotification({ type: 'success', title: window.t('updated') })
                                  }
                                })
                            } else {
                              toastrNotification({ type: 'error', title: window.t('not_updated') })
                            }
                            setSubmitting(false)
                          })
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-row">
                            {survey.questions.map(question => (
                              <div key={question.id} className="form-group col-12 my-3">
                                <label className="mb-1">{question.name}</label>
                                {question.qtype === 'short_answer' &&
                                  <div>
                                    <Field
                                      type="text"
                                      name={'q' + question.id}
                                      className={"form-control " + (errors[`q${question.id}`] && touched[`q${question.id}`] ? 'is-invalid' : '')}
                                      value={values[`q${question.id}`]}
                                    />
                                    {errors[`q${question.id}`] && touched[`q${question.id}`] &&
                                      <div className="invalid-feedback">{errors[`q${question.id}`]}</div>
                                    }
                                  </div>
                                }
                                {question.qtype === 'options_pick_one' &&
                                  <div>
                                    {question.options.map((option, index) => (
                                      <div key={`question_${question.id}_option_${index}`} className="form-check">
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          value={option}
                                          name={`q${question.id}`}
                                          id={`q${question.id}_${index}`}
                                        />
                                        <label className="form-check-label" htmlFor={`q${question.id}_${index}`}>
                                          {option}
                                        </label>
                                      </div>
                                    ))}
                                    {errors[`q${question.id}`] && touched[`q${question.id}`] &&
                                      <div className="invalid-feedback" style={{ display: 'block' }}>{errors[`q${question.id}`]}</div>
                                    }
                                  </div>
                                }
                                {question.qtype === 'options_pick_many' &&
                                  <div>
                                    {question.options.map((option, index) => (
                                      <div key={`question_${question.id}_option_${index}`} className="form-check">
                                        <Field className="form-check-input" type="checkbox" value={option} name={`q${question.id}`} id={`q${question.id}_${index}`} />
                                        <label className="form-check-label" htmlFor={`q${question.id}_${index}`}>
                                          {option}
                                        </label>
                                      </div>
                                    ))}
                                    {errors[`q${question.id}`] && touched[`q${question.id}`] &&
                                      <div className="invalid-feedback" style={{ display: 'block' }}>{errors[`q${question.id}`]}</div>
                                    }
                                  </div>
                                }
                              </div>
                            ))}
                          </div>
                          <div className="row mb-3">
                            <div className="col-12 text-end mt-3">
                              <button type="submit" className="btn btn-primary bnt-lg me-2" disabled={isSubmitting || !isValid || preview}>
                                {window.t('save')}
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}