export function handleRemoveLinkClick(event) {
  console.log('show')
  event.preventDefault();

  const targetLink = event.currentTarget;
  const href = targetLink.getAttribute('href');
  const modalEl = document.getElementById('deleteConfirmationModal');

  if (!modalEl || !href) return;

  const buttonEl = modalEl.querySelector('form.button_to');
  const confirmTextEl = modalEl.querySelector('.confirm-text');
  const confirmTitleEl = modalEl.querySelector('.confirm-title');

  buttonEl.setAttribute('action', href);
  confirmTextEl.innerHTML = targetLink.dataset.confirmText;
  confirmTitleEl.innerHTML = targetLink.dataset.confirmTitle;

  const modal = new bootstrap.Modal(modalEl, {});
  modal.show();
}