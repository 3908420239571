import React from 'react'
import { client } from '../Client'
import CourseSection from './CourseSection'
import VideoPlayer from './VideoPlayer'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import ContentDocument from './ContentDocument'
import ContentSurvey from './ContentSurvey'
import ContentRecording from './ContentRecording'
import ContentText from './ContentText'
import BigBlueButton from './BigBlueButton'
import ContentHomework from './ContentHomework'
import ContentEmpty from './ContentEmpty'
import ContentError from './ContentError'
import FinishCourseMessage from './FinishCourseMessage'
import NoteModal from './NoteModal'
import Conversation from '../Conversation/Conversation'
import arrowDown from '../../images/desktop/arrow-down-3-blake.svg'
import closePlayer from '../../images/desktop/del.png'
import { toastrNotification } from '../../helpers/Toastr'




import './lecture-content.scss'
import { set } from 'lodash'

class CourseViewer extends React.Component {

  constructor(props) {


    super(props)
    const lectionId = this.props.match.params.lectionId,
      lection = this.props.course.lections.find(l => l.id == lectionId)

    this.state = {
      conversation: props.conversation,
      course: props.course,
      trackers: props.trackers,
      notes: props.notes,
      currentLection: lection,
      videoStartTime: 0,
      progress: this.calculateProgress(props.trackers, props.course.lections),
      finishCourseMessageIsActive: false
    }

    this.videoPlayerRef = React.createRef(null)
    this.showMenu = this.showMenu.bind(this);
  }

  componentDidMount() {
    const { course, license } = this.props;

    if (license.need_confirmation) {
      this.intervalID = setInterval(() => {
        client.updateCourseLicenseViewTime(course.id, license.id)
          .then(response => {
            if (response.redirect_url) window.location = response.redirect_url
          })
      }, 60000)
    }
  }

  componentWillUnmount() {
    if (this.intervalId) clearInterval(this.intervalID)
  }

  calculateProgress(trackers, lections) {
    if (lections.length == 0) { return 0 }
    if (this.props.preview) { return 0 }
    return Math.round(trackers.filter(tr => tr.completed_at !== null).length / lections.length * 100)
  }

  updateCourse = (updatedCourse) => {
    this.setState({ course: updatedCourse })
  }

  addNote = (newNote) => {
    this.setState({ notes: [...this.state.notes, newNote] })
  }

  deleteNote = (deletedNote) => {
    const license = this.props.license
    client.deleteCourseNote(license.id, deletedNote.id)
      .then(response => {
        const newNotes = this.state.notes.filter(note => note.id !== deletedNote.id)
        this.setState({ notes: newNotes })
      })
  }

  showDeleteNoteConfirmation = (event, deletedNote) => {
    var self = this
    event.preventDefault()
    $('body').addClass('shadow')
    document.getElementById("delete").onclick = function (event) {
      event.preventDefault()
      self.deleteNote(deletedNote)
      $('body').removeClass('shadow')
    }
  }

  loadLection = (event, lection, time = 0, survey_lection = null) => {

    event.preventDefault()
    if (lection.content.is_stop_block) {
      if (survey_lection == null) return
      else lection = survey_lection
    }

    if (lection.content.is_meeting) {
      if (lection.content.completed || !lection.content.start_soon) return
      const url = Routes.join_meeting_path(lection.content.uid)
      window.location.href = url;
      return
    }

    if (lection.content.status == 'transcoding') return

    const { course, trackers } = this.state,
      locale = this.props.match.params.locale,
      license = this.props.license

    let urlParams = { locale: locale }

    if (!this.props.preview) urlParams['license_id'] = license.id
    if (time > 0) urlParams['t'] = time

    const url = this.props.preview ?
      Routes.tutor_course_lection_path(course.slug, lection.id, urlParams) :
      Routes.pupil_course_lection_path(course.slug, lection.id, urlParams)

    this.setState({
      currentLection: lection,
      finishCourseMessageIsActive: false,
      videoStartTime: time
    }, () => {

      window.history.pushState({}, lection.title, url)
      window.scrollTo(0, 0)

      if (!this.props.preview) {
        client.markLectionStarted(course.id, lection.id, license.id)
          .then(tracker => {
            if (tracker.id) {
              let newTrackers = []
              if (trackers.findIndex(tr => tr.id === tracker.id) !== -1) {
                newTrackers = trackers.map(tr => {
                  if (tr.id === tracker.id) {
                    return tracker
                  } else {
                    return tr
                  }
                })
              } else {
                newTrackers = [...trackers, tracker]
              }

              this.setState({
                trackers: newTrackers,
                progress: this.calculateProgress(newTrackers, course.lections)
              })
            }
          })
      }
    })
  }

  markLectionCompleted = (lection) => {
    if (this.props.preview) { return }

    const trackers = this.state.trackers,
      license = this.props.license

    //return if lection is already completed
    const lectionTracker = trackers.find(tr => tr.lection_id === lection.id)
    if (lectionTracker && lectionTracker.completed_at !== null) {
      return
    }

    client.markLectionCompleted(this.state.course.id, lection.id, license.id)
      .then((tracker) => {
        if (tracker.id) {
          const newTrackers = this.state.trackers.map((tr) => {
            if (tr.id === tracker.id) {
              return tracker
            } else {
              return tr
            }
          })
          this.setState({
            trackers: newTrackers,
            progress: this.calculateProgress(newTrackers, this.state.course.lections)
          })
        }
      })
  }

  showFinishCourseMessage = (event) => {
    const course = this.state.course
    event.preventDefault()
    this.setState({ finishCourseMessageIsActive: true }, () => {
      window.scrollTo(0, 0)
    })
  }

  handleCloseModal = () => {
    $('body').removeClass('shadow')
  }

  renderDeleteConfirmationModal() {
    return (
      <>
        <div className="confirm_modal" id="deleteConfirmationModal">
          <div className="modal_header">
            <div className="container" onClick={this.handleCloseModal}>
              <svg id="close" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M14.75 14.75L1.25 1.25" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.75 1.25L1.25 14.75" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          <div className="container user_form">
            <div className="modal_body d-flex flex-column">
              <h3 className="confirm-title">{window.t("course_viewer.destroy_confirm_title")}</h3>
              <p className="confirm-text">{window.t("course_viewer.destroy_confirm_text")}</p>
              <div className="d-flex justify-content-center">
                <button id="cancel" onClick={this.handleCloseModal} type="button" className="btn btn-pill btn-info my-1 btn-del-room">
                  {window.t('cancel')}
                </button>

                <button id="delete">{window.t('delete')}</button>
              </div>
            </div>
          </div>

        </div>
      </>
    )
  }

  renderLectionContent() {
    const lection = this.state.currentLection
    const videoStartTime = this.state.videoStartTime
    if (lection.content.status == 'transcoding') return

    if (this.state.finishCourseMessageIsActive) {
      return (<FinishCourseMessage course={this.state.course} license={this.props.license} />)
    }

    if (lection.content_id === null && lection.description.length === 0) {
      return (<ContentEmpty />)
    }

    if (lection.content_id === null && lection.description.length > 0) {
      return (<ContentText lection={lection} />)
    }

    if (lection.content.is_recording) {
      return (<BigBlueButton lection={lection} />)
    }

    if (lection.content.is_video) {
      return (<VideoPlayer
        course={this.props.course}
        license={this.props.license}
        currentLection={this.props.currentLection}
        addNote={this.addNote}
        videoPlayerRef={this.videoPlayerRef}
        lection={lection}
        markLectionCompleted={this.markLectionCompleted}
        ref={this.videoPlayerRef}
        videoStartTime={videoStartTime}
      />)
    } else if (lection.content.is_survey) {
      return (<ContentSurvey
        lection={lection}
        markLectionCompleted={this.markLectionCompleted}
        preview={this.props.preview}
        license={this.props.license}
      />)
    } else if (lection.content.is_homework) {
      return (<ContentHomework
        lection={lection}
        markLectionCompleted={this.markLectionCompleted}
        preview={this.props.preview}
      />)
    } else if (lection.content.is_recording) {
      return (<ContentRecording lection={lection} />)
    } else {
      return (<ContentDocument lection={lection} />)
    }
  }

  showMenu(event) {
    let tabs = ['1', '2']
    tabs.splice(tabs.indexOf(event.target.id), 1)
    event.target.classList.add('active')
    document.querySelector(`#tab${event.target.id}`).classList.remove('hide')
    tabs.forEach(function (e) {
      document.getElementById(e).classList.remove('active')
      document.querySelector(`#tab${e}`).classList.add('hide')
    })
  }

  getLectionByNote(note) {
    return this.props.course.lections.find(l => l.id == note.lection_id)
  }

  toggleDescription(event) {
    document.querySelector('.show_more').toggleClass('rotate')
    document.querySelector('#description').toggleClass('hide')
  }

  toggleFullScreen(event) {
    $('.single').toggleClass('full_screen')
    $('.full').toggleClass('rotate')
  }

  stopPlayer = (event, videoPlayerRef) => {
    event.preventDefault()
    if (videoPlayerRef.current) {
      videoPlayerRef.current.pause()
    }
    document.getElementById('currentTime').innerHTML = new Date(videoPlayerRef.current.getCurrentTime() * 1000).toISOString().slice(11, 19)
  }

  hideTextBlockToggle(event) {
    document.querySelector('.main_desc').toggleClass('hide_text_block')
    document.querySelector('.show_more').toggleClass('rotate')
  }

  closePlayer() {
    $('#vjs_video_3').remove()
  }


  render() {
    const { course,
      currentLection,
      trackers,
      notes,
      progress,
      finishCourseMessageIsActive } = this.state

    const { locale } = this.props.match.params
    const { license } = this.props

    return (
      <>
        <div className="player actve">
          <span className="full mobile_none" onClick={this.toggleFullScreen}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M15.5732 19.4933L18.9866 16.08L15.5732 12.6666" stroke="#061310" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5.33301 16.0801H18.893" stroke="#061310" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16 5.33337C21.8933 5.33337 26.6667 9.33337 26.6667 16C26.6667 22.6667 21.8933 26.6667 16 26.6667" stroke="#061310" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>

          <div className="block_top d-flex justify-content-between align-items-center">
            <a className="close mobile_none" href={this.props.returnUrl} onClick={this.closePlayer}>
              <img src={closePlayer} />
            </a>
            <a href="#" className='lessons_title'>
              {currentLection.title}
            </a>
            {this.state.currentLection.content.is_video ?
              <a href="#" onClick={event => this.stopPlayer(event, this.videoPlayerRef)} className="note d-flex align-items-center"
                data-bs-toggle="modal" data-bs-target="#addNote">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.84006 2.90006L3.36673 8.6934C3.16006 8.9134 2.96006 9.34673 2.92006 9.64673L2.6734 11.8067C2.58673 12.5867 3.14673 13.1201 3.92006 12.9867L6.06673 12.6201C6.36673 12.5667 6.78673 12.3467 6.9934 12.1201L12.4667 6.32673C13.4134 5.32673 13.8401 4.18673 12.3667 2.7934C10.9001 1.4134 9.78673 1.90006 8.84006 2.90006Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7.92651 3.8667C8.21318 5.7067 9.70651 7.11337 11.5598 7.30003" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2 15.1667H14" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {window.t('course_viewer.add_note_short')}
              </a>
              :
              ''
            }
          </div>
          {this.renderLectionContent()}
        </div>
        {!this.props.preview &&
          <NoteModal course={this.props.course}
            license={this.props.license}
            currentLection={this.state.currentLection}
            addNote={this.addNote}
            videoPlayerRef={this.videoPlayerRef}
          />
        }
        <div className="wrapper course-viewer">
          <div className="d-flex flex-column horizantal_left">
            <div id="tab0" key="tab0" className="change description" >
              <div className="container">
                <div className="profile_data">
                  <div className="profile_left d-flex align-items-center">
                    <img src={course.author.image_url} alt="" />
                    <div>
                      <div className="name d-flex align-items-center">
                        <span> {course.author.full_name} </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M21.5599 10.7401L20.1999 9.16006C19.9399 8.86006 19.7299 8.30006 19.7299 7.90006V6.20006C19.7299 5.14006 18.8599 4.27006 17.7999 4.27006H16.0999C15.7099 4.27006 15.1399 4.06006 14.8399 3.80006L13.2599 2.44006C12.5699 1.85006 11.4399 1.85006 10.7399 2.44006L9.16988 3.81006C8.86988 4.06006 8.29988 4.27006 7.90988 4.27006H6.17988C5.11988 4.27006 4.24988 5.14006 4.24988 6.20006V7.91006C4.24988 8.30006 4.03988 8.86006 3.78988 9.16006L2.43988 10.7501C1.85988 11.4401 1.85988 12.5601 2.43988 13.2501L3.78988 14.8401C4.03988 15.1401 4.24988 15.7001 4.24988 16.0901V17.8001C4.24988 18.8601 5.11988 19.7301 6.17988 19.7301H7.90988C8.29988 19.7301 8.86988 19.9401 9.16988 20.2001L10.7499 21.5601C11.4399 22.1501 12.5699 22.1501 13.2699 21.5601L14.8499 20.2001C15.1499 19.9401 15.7099 19.7301 16.1099 19.7301H17.8099C18.8699 19.7301 19.7399 18.8601 19.7399 17.8001V16.1001C19.7399 15.7101 19.9499 15.1401 20.2099 14.8401L21.5699 13.2601C22.1499 12.5701 22.1499 11.4301 21.5599 10.7401ZM16.1599 10.1101L11.3299 14.9401C11.1899 15.0801 10.9999 15.1601 10.7999 15.1601C10.5999 15.1601 10.4099 15.0801 10.2699 14.9401L7.84988 12.5201C7.55988 12.2301 7.55988 11.7501 7.84988 11.4601C8.13988 11.1701 8.61988 11.1701 8.90988 11.4601L10.7999 13.3501L15.0999 9.05006C15.3899 8.76006 15.8699 8.76006 16.1599 9.05006C16.4499 9.34006 16.4499 9.82006 16.1599 10.1101Z" fill="#292D32" />
                        </svg>
                      </div>
                      <div className="email d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M6.10671 7.24666C6.04004 7.24 5.96004 7.24 5.88671 7.24666C4.30004 7.19333 3.04004 5.89333 3.04004 4.29333C3.04004 2.65999 4.36004 1.33333 6.00004 1.33333C7.63337 1.33333 8.96004 2.65999 8.96004 4.29333C8.95337 5.89333 7.69337 7.19333 6.10671 7.24666Z" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.9402 2.66667C12.2335 2.66667 13.2735 3.71334 13.2735 5.00001C13.2735 6.26001 12.2735 7.28667 11.0268 7.33334C10.9735 7.32667 10.9135 7.32667 10.8535 7.33334" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M2.77348 9.70667C1.16014 10.7867 1.16014 12.5467 2.77348 13.62C4.60681 14.8467 7.61348 14.8467 9.44681 13.62C11.0601 12.54 11.0601 10.78 9.44681 9.70667C7.62014 8.48667 4.61348 8.48667 2.77348 9.70667Z" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.2266 13.3333C12.7066 13.2333 13.1599 13.04 13.5332 12.7533C14.5732 11.9733 14.5732 10.6867 13.5332 9.90666C13.1666 9.62666 12.7199 9.43999 12.2466 9.33333" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {course.author.customers_count} учеников
                      </div>
                    </div>
                  </div>
                  <div>
                    <a href={Routes.market_tutor_path(course.author.subdomain)} className="profile_btn">Перейти</a>
                  </div>
                </div>
                {course.description ?
                  <p className="main_desc hide_text_block" dangerouslySetInnerHTML={{ __html: course.description }}>
                  </p>
                  :
                  ''
                }
                {course.description && course.description.length > 200 ?
                  <div onClick={this.hideTextBlockToggle} className="show_more d-flex align-items-center">
                    Читать полностью
                    <img src={arrowDown} />
                  </div>
                  :
                  ''
                }
              </div>
              <div className="tab_items d-flex">
                {/* <div id="0" className="desc_none" onClick={this.showMenu}>Описание</div> */}
                <div id="1" className="active" onClick={this.showMenu}>Модули</div>

                {!this.props.preview && <div id="2" onClick={this.showMenu}>Чат курса</div>}
              </div>
            </div>
            <div className="tab_block">

              <div id="tab1" key="tab1" className="change lessons_block flex-column">
                <div className="lessons lessons_viewer d-flex flex-column" id="accordionSections" >
                  {course.sections.map((section, index) => (
                    <CourseSection
                      key={'section' + section.id}
                      section={section}
                      course={course}
                      index={index}
                      locale={locale}
                      currentLection={currentLection}
                      loadLection={this.loadLection}
                      trackers={trackers}
                      notes={notes}
                      isOpened={section.lections.findIndex(l => l.id === currentLection.id) !== -1}
                      showFinishCourseLink={index === course.sections.length - 1 && progress === 100}
                      showFinishCourseMessage={this.showFinishCourseMessage}
                      finishCourseMessageIsActive={finishCourseMessageIsActive}
                      deleteNote={this.deleteNote}
                    />
                  ))}
                </div>
              </div>
              {!this.props.preview &&
                <div id="tab2" className="change chat chat-small hide">
                  <Conversation
                    conversation={this.state.conversation}
                    canUpdateConversation={'canUpdateConversation'}
                    currentUserId={license.user.id}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default CourseViewer
