import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String
  }

  async close() {
    this.element.classList.add("alert-download-app-hidden");

    await post(this.urlValue);
  }
}
