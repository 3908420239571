import React from 'react'
import { client } from './../components/Client'
import Uppy from '@uppy/core'
import Russian from '@uppy/locales/lib/ru_RU'
import Tus from '@uppy/tus'
import { DashboardModal } from '@uppy/react'
import { toastrNotification } from './Toastr'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

class FileUploader extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      modalOpen: false,
      uploadedFiles: 0,
      isReady: true,
    }

    const locale = window.locale == "ru" ? Russian : 'en_EN'

    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: this.props.maxNumberOfFiles || 10,
        allowedFileTypes: this.props.allowedFileTypes || ['*']
      },
      autoProceed: false,
      locale: locale
    }).use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5*1024*1024 })

    this.uppy.on('upload-success', (file, response) => {
      let fileData = {title: file.name,
                      file_orig_name: file.name,
                      file_url: response.uploadURL,
                      file_size: file.size,
                      file_type: file.meta.type}

      this.createFile(fileData)
    })

    //this.uppy.on('file-removed', (file, reason) => {
      //if (file.uploadURL) {
      //  client.deleteFile(file.uploadURL)
      //}
    //})

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentWillUnmount () {
    this.uppy.close()
  }

  handleOpen() {
    this.setState({ modalOpen: true })
  }

  handleClose () {
    this.setState({ modalOpen: false })
  }

  createFile(fileData) {
    client.createFile(fileData)
    .then(response => {
      if (response.success) {
        this.setState({ uploadedFiles: this.state.uploadedFiles + 1 })
        this.props.onFileUploadSuccess(response.file_id)
      }
    })
  }

  render () {
    return (
      <>
        <button type="button" className={this.props.buttonStyles} onClick={this.handleOpen} disabled={!this.state.isReady}>
          { this.props.buttonTitle }
        </button>
        <DashboardModal
          uppy={this.uppy}
          closeModalOnClickOutside
          open={this.state.modalOpen}
          onRequestClose={this.handleClose}
          target={document.body}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          showRemoveButtonAfterComplete={false}
          doneButtonHandler={this.handleClose}
        />
      </>
    );
  }
}

export default FileUploader