import React from 'react'

const ContentText = ({ lection }) => {
  return (
    <div className="lecture-content">
      <div className="lecture-container">
        <div className="row">
          <div className="col-12">
            <div className="card overflow-auto" style={{ zIndex: 10 }}>
              <div className="card-title rounded-3 text-center fw-bold pt-3">{lection.title}</div>
              <div className="rounded-3 mx-3 mt-0 mb-3" dangerouslySetInnerHTML={{ __html: lection.description }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentText