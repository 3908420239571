import React, { useState } from 'react'
import QuestionForm from './QuestionForm'
import { client } from '../../Client'
import { toastrNotification } from '../../../helpers/Toastr'

export default function SurveyQuestion({
  position,
  survey,
  question,
  setQuestion,
  removeQuestion,
  moveQuestionUp,
  moveQuestionDown
}) {
  const [prevQuestion, setPrevQuestion] = useState(question)
  const [editing, setEditing] = useState(!question.persisted)
  const [submitting, setSubmitting] = useState(false)

  function toggleEditing() {
    setEditing(!editing)
  }

  function saveQuestion() {
    const data = {
      name: question.text,
      qtype: question.type,
      required: question.required,
      correct_answers: question.correct_answers,
      options: question.options
    }

    if (question.hasOptions && question.options.length === 0) {
      toastrNotification({ type: 'warning', title: window.t('survey.enter_options') })
      return
    }

    setSubmitting(true)

    if (question.persisted) {
      client.updateSurveyQuestion(survey.id, question.id, data)
        .then(response => {
          if (response.id) {
            setEditing(false)
          } else {
            toastrNotification({ type: 'error', title: window.t('not_updated') })
          }
          setSubmitting(false)
        })
    } else {
      client.createSurveyQuestion(survey.id, data)
        .then(response => {
          if (response.id) {
            setEditing(false)
          } else {
            toastrNotification({ type: 'error', title: window.t('not_updated') })
          }
          setSubmitting(false)
        })
    }
  }

  function handleCancelChanges() {
    if (!question.persisted) {
      removeQuestion()
    } else {
      setQuestion(question.merge({
        text: prevQuestion.text,
        type: prevQuestion.type,
        options: prevQuestion.options,
        correct_answers: prevQuestion.correct_answers,
        required: prevQuestion.required
      })
      )
    }
    setEditing(false)
  }

  function handleQuestionRemove() {
    if (!question.persisted) {
      removeQuestion()
      return
    }

    client.deleteSurveyQuestion(survey.id, question.id)
      .then(response => {
        removeQuestion()
      })
  }

  return (
    <div className="card mb-3 rounded-3 border">
      <div className="card-body">
        {editing ? (
          <QuestionForm question={question} setQuestion={setQuestion} />
        ) : (
          <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
            <h6 onClick={e => setEditing(true)} style={{ cursor: 'pointer' }}><span className="badge bg-secondary mg-r-5 m-1">{position + 1}</span> {question.text} {question.required ? "*" : ""}</h6>
            <div className="d-flex mg-t-10 mg-sm-t-0">
              <button onClick={e => setEditing(true)} className="btn btn-sm btn-white border btn-icon me-2">
                <i className="fas fa-pen" />
              </button>
              <button onClick={e => handleQuestionRemove()} disabled={submitting} className="btn btn-sm btn-white border  btn-icon me-2">
                <i className="fas fa-trash-alt" />
              </button>
              <button onClick={moveQuestionUp} className="btn btn-sm btn-white border me-2 btn-icon">
                <i className="fas fa-angle-up" />
              </button>
              <button onClick={moveQuestionDown} className="btn btn-sm btn-white border btn-icon">
                <i className="fas fa-angle-down" />
              </button>
            </div>
          </div>
        )}

        {editing &&
          <div className="row">
            <div className="col-12 mt-2">
              <button onClick={e => saveQuestion()} disabled={submitting} className="btn btn-white border btn-xs me-1">
                <i className="fas fa-save icon" />
                {window.t('save')}
              </button>
              <button onClick={e => handleCancelChanges()} disabled={submitting} className="btn btn-white border btn-xs">
                <i className="fas fa-undo icon" />
                {window.t('cancel')}
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
