import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text"];

  handleChange(e){
    const file = e.target.files[0];

    if(file) {
      this.textTarget.innerText = file.name;
    }
    else {
      this.textTarget.innerText = "Файл не выбран"
    }
  }
}
