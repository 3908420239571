import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static get targets() {
    return ['completed'];
  }

  static values = {
    enrolledData : String,
    earningData : String,
    totalEarningTutor : String
  }

  connect() {
    if (this.enrolledDataValue) {
      var enrolledData = JSON.parse(this.enrolledDataValue);
      chart('chartEnrolled', enrolledData, ThemeColor.getCssVariableValue('--bs-success'), false)
    }
    if (this.earningDataValue) {
      var earningData = JSON.parse(this.earningDataValue)
      chart('chartTotalEarning', earningData, ThemeColor.getCssVariableValue('--bs-purple'), false)
    }

    if (this.totalEarningTutorValue) {
      var earningData = JSON.parse(this.totalEarningTutorValue)
      chart('chartTotalEarningTutor', earningData, ThemeColor.getCssVariableValue('--bs-success'), true)
    }
  }

}

function chart(id, data, chartColor, labels){
  const element = document.querySelector("#"+id);

  if (typeof(element) != 'undefined' && element != null) {
    const options = {
      series: [{
        name: 'Payout',
        data: data[0]
      }],
      chart: {
        height: 300,
        type: 'area',
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: labels
      },
      stroke: {
        curve: 'smooth'
      },
      colors: [chartColor],
      xaxis: {
        type: 'Payout',
        categories: data[1],
        axisBorder: {
           show: false
        },
        axisTicks: {
            show: false
        },
      },
      yaxis: [{
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
      }],
      tooltip: {
        y: {
          title: {
            formatter: function (e) {
              return "" + "";
            }
          }
        },
        marker: {
          show: !1
        }
      }
    };

    const chart = new ApexCharts(element, options);

    chart.render();
  }
}
