import React, { useState, useEffect, useMemo } from 'react'
import { client } from '../../Client'
import Loader from '../../../helpers/Loader'
import DataTable from 'react-data-table-component'
import SurveyReportRowExpanded from './SurveyReportRowExpanded'

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="row">
    <div className="col-12">
      <div className="input-group mb-3">
        <input id="search" type="text" className="form-control" placeholder={window.t('course.filter_by_name')} value={filterText} onChange={onFilter} aria-label="Recipient's username" />
        <div className="input-group-append">
          <button type="button" className="btn btn-primary" onClick={onClear}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default function SurveyReport({ survey }) {
  const [columns, setColumns] = useState([])
  const [answers, setAnswers] = useState([])
  const [fetching, setFetching] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const filteredAnswers = answers.filter(answer => answer.user_name && answer.user_name.toLowerCase().includes(filterText.toLowerCase()))

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  }, [filterText, resetPaginationToggle])

  useEffect(() => {
    setFetching(true)
    client.getSurveyAnswers(survey.id).then(response => {
      let tableColumns = [{
        name: window.t('course.license.user_name'),
        selector: 'user_name',
        sortable: true
      }]

      response.questions.forEach((question, i) => {
        tableColumns.push({
          name: question.name,
          selector: `q${question.id}`,
          sortable: false
        })
      })

      setColumns(tableColumns)
      setAnswers(response.answers)
      setFetching(false)
    })
  }, [])

  return (
    <div className="container mt-6">
      <div className="card bg-transparent border rounded-3 mb-5">
        <div className="card-body">
          <div className="d-md-flex d-block align-items-center justify-content-between my-2">
            <div className='row'>
              {fetching && <Loader />}
              {!fetching && answers.length === 0 &&
                <div className="text-center">
                  {window.t('survey.no_answers')}
                </div>
              }
              {!fetching && answers.length > 0 &&
                <DataTable
                  columns={columns}
                  data={filteredAnswers}
                  responsive={true}
                  pagination
                  paginationTotalRows={answers.length}
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  expandableRows
                  expandableRowsComponent={<SurveyReportRowExpanded questions={columns} />}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

