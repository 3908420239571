import { Controller } from "@hotwired/stimulus";
import { toastrNotification } from '../helpers/Toastr'

export default class extends Controller {
  static values = {
    text: String
  };

  copy() {
    navigator.clipboard.writeText(this.textValue).then(() => {
      toastrNotification({ type: 'success', title: window.t('course.copy_link') });
    }).catch((err) => {
      toastrNotification({ type: 'error', title: err });
    });
  }
}
