import { Controller } from "@hotwired/stimulus"
import { func } from "prop-types";

export default class extends Controller {

  static values = {
  }

  connect() {
    const copyButton = document.getElementById('copy');
    const inviteUrlInput = document.getElementById('invite-url');
    copyButton.addEventListener('click', () => {
      inviteUrlInput.select();
      inviteUrlInput.setSelectionRange(0, 99999); // For mobile devices

      navigator.clipboard.writeText(inviteUrlInput.value).then(() => {
        console.log('Text copied to clipboard');
      }).catch(err => {
        console.error('Oops, unable to copy', err);
      });

    });
  }


}



