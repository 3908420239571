import React from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import NoteModal from './NoteModal'


// The following registers the plugin with `videojs`
require('@silvermine/videojs-quality-selector')(videojs)

import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css'
// video.js player from the docs: https://github.com/videojs/video.js/blob/master/docs/guides/react.md

const playerOptions = {
  fill: true,
  fluid: true,
  preload: 'auto',
  controls: true,
  responsive: true,
  autoplay: true,
  aspectRatio: '16:9',
  playbackRates: [0.50, 0.75, 1, 1.25, 1.5, 2],
  html5: {
    hls: {
      enableLowInitialPlaylist: true,
      smoothQualityChange: true,
      overrideNative: true,
    },
    nativeVideoTracks: false,
    nativeAudioTracks: false,
    nativeTextTracks: false,
  },
}

class VideoPlayer extends React.Component {

  componentDidMount() {
    // const video = this.props.lection.content
    // const status = this.props.lection.content.status
    // const urlParams = new URLSearchParams(window.location.search)
    // const time = urlParams.get('t') || 0
    // const videoStartTime = this.props.videoStartTime || time
    // console.log('Mount', videoStartTime)

    // this.player = videojs(this.videoNode, playerOptions)
    // this.player.controlBar.addChild('QualitySelector')
    // if (process.env.NODE_ENV === 'development') {
    //   this.player.src( [{ type: video.file_type, src: video.url, label: 'hd' }] )
    // } else {
    //   if (video.multiformat) {
    //     this.player.src([{ type: 'application/vnd.apple.mpegurl',
    //                        src: video.hls_sd_url,
    //                        label: 'sd'
    //                      },
    //                      { type: 'application/vnd.apple.mpegurl',
    //                        src: video.hls_hd_url,
    //                        label: 'hd',
    //                        selected: "true",
    //                     }])
    //   } else {
    //     this.player.src([{ type: 'application/vnd.apple.mpegurl',
    //                        src: video.hls_url,
    //                        label: 'hd'
    //                    }])
    //   }
    const video = this.props.lection.content
    const status = this.props.lection.content.status
    const urlParams = new URLSearchParams(window.location.search)
    const time = urlParams.get('t') || 0
    const videoStartTime = this.props.videoStartTime || time

    videojs.Hls.xhr.beforeRequest = function (options) {
      options.headers = {
        'Authorization': process.env.MEDIA_SERVER_API_KEY
      };

      return options;
    }

    this.player = videojs(this.videoNode, playerOptions)
    this.player.controlBar.addChild('QualitySelector')
    if (process.env.NODE_ENV === 'development') {
      this.player.src([{ type: video.file_type, src: video.url, label: 'hd' }])
    } else {
      if (video.multiformat) {
        this.player.src([{
          type: 'application/vnd.apple.mpegurl',
          src: video.hls_sd_url,
          label: 'sd'
        },
        {
          type: 'application/vnd.apple.mpegurl',
          src: video.hls_hd_url,
          label: 'hd',
          selected: "true",
        }])
      } else {
        this.player.src([{
          type: 'application/vnd.apple.mpegurl',
          src: video.hls_url,
          label: 'hd'
        }])
      }
    }


    //this.player.on('ended', () => {
    //  this.props.markLectionCompleted(this.props.lection)
    //})


    if (videoStartTime > 0) {
      this.player.currentTime(videoStartTime)
    }

    this.intervalID = setInterval(this.checkPlayerProgress.bind(this), 3000)
  }

  checkPlayerProgress() {
    let percentComplete = this.player.currentTime() / this.player.duration() * 100
    if (percentComplete >= 90) {
      this.props.markLectionCompleted(this.props.lection)
    }
  }

  getCurrentTime() {
    return this.player.currentTime()
  }

  setCurrentTime(time) {
    this.player.currentTime(time)
  }

  pause() {
    this.player.pause()
  }

  play() {
    this.player.play()
  }

  componentWillUnmount() {
    if (this.intervalID) {
      clearInterval(this.intervalID)
    }
  }

  componentDidUpdate(prevProps) {

    if (this.player && prevProps.lection.id !== this.props.lection.id) {
      const video = this.props.lection.content
      const videoStartTime = this.props.videoStartTime || 0
      if (process.env.NODE_ENV === 'development') {

        this.player.src([{ type: video.file_type, src: video.url, label: 'hd' }])
      } else {
        if (video.multiformat) {
          this.player.src([{
            type: 'application/vnd.apple.mpegurl',
            src: video.hls_sd_url,
            label: 'sd'
          },
          {
            type: 'application/vnd.apple.mpegurl',
            src: video.hls_hd_url,
            label: 'hd',
            selected: "true",
          }])
        } else {
          this.player.src([{
            type: 'application/vnd.apple.mpegurl',
            src: video.hls_url,
            label: 'hd'
          }])
        }
      }

      if (videoStartTime > 0) {
        this.player.currentTime(videoStartTime)
      }

    }

    if (this.player &&
      prevProps.lection.id === this.props.lection.id &&
      prevProps.videoStartTime !== this.props.videoStartTime) {

      const videoStartTime = this.props.videoStartTime || 0
      if (videoStartTime > 0) {
        this.player.currentTime(videoStartTime)
      }

      this.player.play()

    }
  }

  showNoteModal(e) {
    e.preventDefault()
    if (this.props.videoPlayerRef.current) {
      // setPlayerTime(videoPlayerRef.current.getCurrentTime())
      this.props.videoPlayerRef.current.pause()
    }
    document.getElementById('addNoteModal').classList.add('shadow')
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  // use `ref` to give Video JS a reference to the video DOM element: https://reactjs.org/docs/refs-and-the-dom
  render() {
    return (
      // <div className="video-player rounded-3">
      //   <div data-vjs-player>
      //     <video ref={ node => this.videoNode = node } className="video-js vjs-default-skin vjs-big-play-centered"></video>
      //   </div>
      // </div>
      <div className="player">
        <div className="play_top ">
          <a href="#">{this.props.lection.title}</a>
          <a href="#" className="note d-flex align-items-center" onClick={e => this.showNoteModal(e)}>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.84006 2.90006L3.36673 8.6934C3.16006 8.9134 2.96006 9.34673 2.92006 9.64673L2.6734 11.8067C2.58673 12.5867 3.14673 13.1201 3.92006 12.9867L6.06673 12.6201C6.36673 12.5667 6.78673 12.3467 6.9934 12.1201L12.4667 6.32673C13.4134 5.32673 13.8401 4.18673 12.3667 2.7934C10.9001 1.4134 9.78673 1.90006 8.84006 2.90006Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.92651 3.8667C8.21318 5.7067 9.70651 7.11337 11.5598 7.30003" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M2 15.1667H14" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {window.t('course_viewer.add_note_short')}
          </a>
          {!this.props.preview &&
            <NoteModal course={this.props.course}
              license={this.props.license}
              currentLection={this.props.lection}
              addNote={this.props.addNote}
              videoPlayerRef={this.props.videoPlayerRef}
            />
          }
        </div>
        <div className="play_center d-flex align-items-center">
          <svg className="prev" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.11008 5.0799C9.98008 4.8199 10.9401 4.6499 12.0001 4.6499C16.7901 4.6499 20.6701 8.5299 20.6701 13.3199C20.6701 18.1099 16.7901 21.9899 12.0001 21.9899C7.21008 21.9899 3.33008 18.1099 3.33008 13.3199C3.33008 11.5399 3.87008 9.8799 4.79008 8.4999" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.87012 5.32L10.7601 2" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.87012 5.32007L11.2401 7.78007" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <svg className="play_btn" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_1_4292)">
              <circle cx="23" cy="23" r="23" fill="white" fillOpacity="0.16" />
            </g>
            <path d="M32 23L18.5 30.7942L18.5 15.2058L32 23Z" fill="white" />
            <defs>
              <filter id="filter0_b_1_4292" x="-2" y="-2" width="50" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_4292" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1_4292" result="shape" />
              </filter>
            </defs>
          </svg>
          <svg className="next" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14.8901 5.0799C14.0201 4.8199 13.0601 4.6499 12.0001 4.6499C7.21008 4.6499 3.33008 8.5299 3.33008 13.3199C3.33008 18.1199 7.21008 21.9999 12.0001 21.9999C16.7901 21.9999 20.6701 18.1199 20.6701 13.3299C20.6701 11.5499 20.1301 9.8899 19.2101 8.5099" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.13 5.32L13.24 2" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.13 5.32007L12.76 7.78007" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        {/* <img src={this.props.lection.image_url} alt=""/> */}
        {/* <video src="https://quiet-salmiakki-9d1bc7.netlify.app/videos/video1.mp4" controls style={{width: '100%',  height: '100%'}}></video> */}
        <video ref={node => this.videoNode = node} className="video-js vjs-default-skin vjs-big-play-centered" controls style={{ width: '100%', height: '100%' }}></video>

      </div>
    )
  }
}

export default VideoPlayer