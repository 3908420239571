import React from 'react'
import ReactDOM from 'react-dom'
import FileUploader from './FileUploader'

document.addEventListener('turbo:load', () => {
  const currentLocale = window.locale
  const uploaderBtnContainer = document.getElementById('fileUploaderBtn')

  if (uploaderBtnContainer) {
    ReactDOM.render(
      <FileUploader
        currentLocale={currentLocale}
      />,
      uploaderBtnContainer
    );
    //document.addEventListener('turbo:before-visit', () => {
    //  ReactDOM.unmountComponentAtNode(uploaderBtnContainer)
    //})
  }

})