import React from 'react'
import Details from './Details'
import Price from './Price'
import Curriculum from './Curriculum'
import Publication from './Publication'
import CourseCreatorHeader from './CourseCreatorHeader'
import PackageItems from './PackageItems'
import { Route, Switch } from 'react-router-dom'
import galleryImg from '../../images/element/gallery.svg'
import abouteImg from '../../images/about/04.jpg'

class CourseCreator extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      course: props.course,
      stream: props.stream,
      categories: props.categories,
      certificateTemplates: props.certificateTemplates
    }
  }

  updateCourse = (updatedCourse) => {
    this.setState({ course: updatedCourse })
  }

  render() {
    const { course, categories, certificateTemplates } = this.state,
          { locale } = this.props.match.params,
          pathname = this.props.location.pathname;

    return (
    <div>
      <CourseCreatorHeader course={course} locale={locale} pathname={pathname} />

      <Switch>
        <Route
          exact
          path="/:locale/tutor/courses/:courseId/edit"
          render={(props) => <Details {...props} course={course} categories={categories} certificateTemplates={certificateTemplates} onUpdateCourse={this.updateCourse} />}
        />
        <Route
          exact
          path="/:locale/tutor/courses/:courseId/curriculum/edit"
          render={(props) => <Curriculum {...props} course={course} onUpdateCourse={this.updateCourse} />}
        />
        <Route
          exact
          path="/:locale/tutor/courses/:courseId/package_items/edit"
          render={(props) => <PackageItems {...props} course={course} onUpdateCourse={this.updateCourse} />}
        />
        
        <Route
          exact
          path="/:locale/tutor/courses/:courseId/price/edit"
          render={(props) => <Price {...props} course={course} onUpdateCourse={this.updateCourse} />}
        />
        <Route
          exact
          path="/:locale/tutor/courses/:courseId/publication/edit"
          render={(props) => <Publication {...props} course={course} onUpdateCourse={this.updateCourse} />}
        />
      </Switch>
      </div>
    )
  }
}

export default CourseCreator
