import React from 'react'
import { client } from '../Client'
import Uppy from '@uppy/core'
import Russian from '@uppy/locales/lib/ru_RU'
import Tus from '@uppy/tus'
import { DashboardModal } from '@uppy/react'
import { toastrNotification } from '../../helpers/Toastr'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

class ChatFileUploader extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      uploadedFiles: 0,
      isReady: false
    }

    const locale = window.locale == "ru" ? Russian : 'en_EN'

    this.uppy = new Uppy({
      restrictions: { maxNumberOfFiles: 10 },
      autoProceed: true,
      locale: locale
    }).use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5*1024*1024 })

    this.uppy.on('upload-success', (file, response) => {
      let fileData = {title: file.name,
                      file_orig_name: file.name,
                      file_url: response.uploadURL,
                      file_size: file.size,
                      file_type: file.meta.type}
      this.createConversationFile(fileData)
    })

    this.uppy.on('file-removed', (file, reason) => {
      if (file.uploadURL && reason === 'removed-by-user') {
        client.deleteFile(file.uploadURL)
      }
    })

    this.uppy.on('dashboard:modal-closed', () => {
      this.props.handleFileUploaderClose()
    })
  }

  createConversationFile(fileData) {
    console.log('Create file')
    
    client.createConversationFile(fileData, this.props.conversation.id)
    .then((response) => {
      if (response.file_id) {
        console.log(response.file_id)

        this.setState({ uploadedFiles: this.state.uploadedFiles + 1 })
        this.props.subscription.perform('create_conversation_message',
                                         { message: "",
                                           attachments: [response.file_id] })
      }
    })
  }

  render () {
    return (
      <div>
        <DashboardModal
          uppy={this.uppy}
          closeModalOnClickOutside
          open={this.props.open}
          target={document.body}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          showRemoveButtonAfterComplete={false}
          doneButtonHandler={this.props.handleFileUploaderClose}
        />
      </div>
    );
  }
}

export default ChatFileUploader