import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'
import { client } from '../Client'

const NoteModal = ({course, license, currentLection, addNote, videoPlayerRef}) => {
  const [show, setShow] = useState(false)
  const [playerTime, setPlayerTime] = useState(0)

  const handleClose = (values) => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.play()
    }
    setShow(false)
  }
  const closeModal = () =>{
    var modal = document.getElementById('addNote')
    document.getElementById('noteTitle').value = ''
    var modalInstance = bootstrap.Modal.getInstance(modal);
    modalInstance.hide();
    if (videoPlayerRef.current) {
      videoPlayerRef.current.play()
    }
    setShow(false)
  }

  const handleShow = () => {
    if (videoPlayerRef.current) {
      setPlayerTime(videoPlayerRef.current.getCurrentTime())
      videoPlayerRef.current.pause()
    }
    setShow(true)
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(window.t('form.required')),
  })
  
  return (
    
    <>
    {/* <div id="addNoteModal" > */}

    <div className="modal fade mobile_none" id="addNote" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal_top d-flex align-items-center justify-content-between">
          { window.t('course_viewer.add_new_note') }
            <svg data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M14.75 1.25L1.25 14.75" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.75 14.75L1.25 1.25" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <img src={course.image_url} alt=""/>
          <ul>
            <li className="d-flex justify-content-between"> Курс <span>{course.title}</span>
            </li>
            <li className="d-flex justify-content-between"> Модуль <span>{currentLection.title}</span>
            </li>
            <li className="d-flex justify-content-between"> Время <span id="currentTime">{videoPlayerRef.current !=  null ? Math.round(videoPlayerRef.current.getCurrentTime()) : '-'}</span>
            </li>
          </ul>
          <Formik
            initialValues={{ title: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              let data = values
              data['lection_id'] = currentLection.id
              data['time_seconds'] = Math.round(videoPlayerRef.current.getCurrentTime())
              client.createCourseNote(license.id, data)
              .then(note => {
                if (note.id) {
                  addNote(note)
                  toastrNotification({ type: 'success', title: window.t('created') })
                  handleClose()
                  closeModal()
                  } else {
                  toastrNotification({ type: 'error', title:  window.t('not_created') })
                }
                setSubmitting(false)
              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="form-label">{ window.t('course_viewer.type_your_note') }</label>
                    <input
                      type="text"
                      name="title"
                      id="noteTitle"
                      className={"form-control " + (errors.title && touched.title ? 'is-invalid' : '')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // value={values.title}
                      placeholder={ window.t('course_viewer.type_your_note') }
                    />
                    {errors.title && touched.title &&
                      <div className="invalid-feedback">{errors.title}</div>
                    }
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <Button variant="secondary" onClick={closeModal}>
                    { window.t('cancel') }
                  </Button>
                  <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
                    { window.t('save') }
                  </Button>
                </div>
              </form>
            )}
          </Formik>
          {/* <input type="text" placeholder="Введите текст заметки"  />
          <button>Добавить</button> */}
        </div>
      </div>
    </div>
      
      {/* <Modal show={show} onHide={handleClose} className='SSS'>
        <Modal.Header closeButton>
          <Modal.Title>{ window.t('course_viewer.add_new_note') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ title: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              let data = values
              data['lection_id'] = currentLection.id
              data['time_seconds'] = Math.round(playerTime)
              client.createCourseNote(license.id, data)
              .then(note => {
                if (note.id) {
                  addNote(note)
                  toastrNotification({ type: 'success', title: window.t('created') })
                  handleClose()
                } else {
                  toastrNotification({ type: 'error', title:  window.t('not_created') })
                }
                setSubmitting(false)
              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="form-label">{ window.t('course_viewer.type_your_note') }</label>
                    <input
                      type="text"
                      name="title"
                      className={"form-control " + (errors.title && touched.title ? 'is-invalid' : '')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      placeholder={ window.t('course_viewer.type_your_note') }
                    />
                    {errors.title && touched.title &&
                      <div className="invalid-feedback">{errors.title}</div>
                    }
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-5">
                  <Button variant="secondary" onClick={handleClose}>
                    { window.t('cancel') }
                  </Button>
                  <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
                    { window.t('save') }
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal> */}
    {/* </div> */}
    </>
  )
}

export default NoteModal