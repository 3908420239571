import React, { useState, useEffect } from 'react'
import { client } from './../Client'
import Loader from '../../helpers/Loader'
import { Modal, Button } from 'react-bootstrap'
import { toastrNotification } from '../../helpers/Toastr'

export default function PackageItemsDeleteModal({ course, packageItems }) {
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState([])
  const [courses, setCourses] = useState(packageItems.map(pi => pi.course))
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [updateLicenses, setUpdateLicenses] = useState(false)

  useEffect(() => {
    const filteredCourses = courses.map(course => {
      if (course.title.toLowerCase().includes(search.toLowerCase())) {
        course.hidden = false
      } else {
        course.hidden = true
      }
      return course
    })
    setCourses(filteredCourses)
  }, [search])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCheck = (courseId) => {
    if (checked.includes(courseId)) {
      setChecked(checked.filter(ch => ch !== courseId))
    } else {
      setChecked([...checked, courseId])
    }
  }

  const handleSearch = (ev) => {
    setSearch(ev.target.value)
  }

  const handleUpdateLicensesClick = () => {
    setUpdateLicenses(!updateLicenses)
  }

  const submit = () => {
    if (checked.length === 0) {
      toastrNotification({ type: 'warning', title: window.t('course.you_need_to_select_courses') })
      return
    }
    setDisabled(true)
    client.deleteCoursePackageItems(course.id, checked, updateLicenses).then(response => {
      setDisabled(false)
      setOpen(false)
      Turbo.visit(window.location)
    })
  }

  return (
    <>
      <a className="dropdown-item" onClick={handleOpen}>
        <i className="fas fa-trash me-1" />{ window.t("delete") }
      </a>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title tx-16">
            { window.t('course.select_package_courses_you_want_to_delete') }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { loading && <Loader /> }

          { !loading && courses.length > 0 &&
            <div>
              <div className="search-form mb-3 input-group">
                <input type="search" className="form-control" placeholder={window.t('search')} onChange={handleSearch} />
              </div>

              <div className="mt-3" style={{maxHeight: "250px", overflowY: "auto"}}>
                {
                  courses.filter(course => !course.hidden).map(course => (
                    <div className="form-check mb-2" key={"course_"+course.id}>
                      <input type="checkbox" checked={checked.includes(course.id)} className="form-check-input" id={"course" + course.id} onChange={() => handleCheck(course.id)} />
                      <label className="form-check-label" htmlFor={"course" + course.id}>{course.title}</label>
                    </div>
                  ))
                }
              </div>

              <hr/>

              <div className="form-check mt-3">
                <input type="checkbox" checked={updateLicenses} className="form-check-input" id="updateLicenses" onChange={handleUpdateLicensesClick} />
                <label className="form-check-label" htmlFor="updateLicenses">{ window.t('course.update_course_licenses') }</label>
              </div>
            </div>
          }

          { !loading && courses.length == 0 &&
            <div className="text-center">
              { window.t('course.no_available_courses_for_package') }
            </div>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" disabled={disabled} onClick={submit} >
            { disabled &&
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            }
            &#160;{ window.t('delete') }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}