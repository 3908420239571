import React, { Component } from 'react'
import { client } from '../Client'
import ChatFileUploader from './ChatFileUploader'
import mediumEditor from 'medium-editor/dist/js/medium-editor'
import 'medium-editor/dist/css/medium-editor.min.css'
import 'medium-editor/dist/css/themes/default.min.css'

import ClickOutsideTracker from '../../helpers/ClickOutsideTracker'

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

class ConversationMessageForm extends Component {

  state = {
    minTextLength: 0,
    editor: Object,
    editableContent: null,
    fileUploaderIsOpen: false,
    showEmojiPicker: false
  }

  componentDidMount = () => {
    let _this = this;

    let editor = new mediumEditor("#conversation-text-editor", {
        placeholder: {
          text: window.t("chat.conversation.write_message"),
          hideOnClick: true
        },
        delay: 1000,
        targetBlank: true,
        toolbar: false,
        autoLink: true
    })

    document.addEventListener('keydown', this.keydownHandler)

    this.setState({ editor: editor })
  }

  keydownHandler = (e) => {
    if (e.keyCode===13 && e.ctrlKey) this.sendMessage()
  }

  componentWillUnmount() {
   document.removeEventListener('keydown', this.keydownHandler)
  }

  getContent() {
    return this.state.editor.getContent(0)
  }

  getSerializedContent() {
    return this.state.editor.serialize()
  }

  isValid() {
    let content = this.state.editor.getContent(0)
    if (content === "<p><br></p>") return false
    return content.length > this.state.minTextLength
  }

  reset() {
    return this.state.editor.setContent('', 0);
  }

  addEmoji = (emoji, event) => {
    if (!emoji) return false;
    //const imageHtml = `<img class='emojione' alt='${emoji.colons}' title='${emoji.colons}' src='https://cdn.jsdelivr.net/emojione/assets/4.0/png/32/${emoji.unified.split('-')[0]}.png'>`
    const imageHtml = emoji.native
    if (this.state.selectionRange) {
      mediumEditor.selection.selectRange(document, this.state.selectionRange)
    } else {
      this.state.editor.selectElement(document.getElementById("conversation-text-editor"));
      mediumEditor.selection.clearSelection(document, false)
    }
    this.state.editor.pasteHTML(imageHtml, { cleanAttrs: [] });
  }

  toggleEmojiPicker = (event) => {
    event.preventDefault()
    this.focus()
    this.setState({ showEmojiPicker: !this.state.showEmojiPicker })
  }

  closeEmojiPicker = () => {
    this.setState({ showEmojiPicker: false });
  }

  //loadContent = (content) => {
  //  this.setState({ editableContent: content })
  //}

  focus() {
    const element = document.getElementById('conversation-text-editor');
    element.focus();
  }

  sendMessage = (ev) => {
    if ( ev ) { ev.preventDefault() }
    if ( this.isValid() ) {
      this.props.subscription.perform('create_conversation_message', { message: this.getContent() });
    } else {
      this.focus();
      return false
    }
  }

  openFilesUploader = () => {
    this.setState({ fileUploaderIsOpen: true })
  }

  closeFilesUploader = () => {
    this.setState({ fileUploaderIsOpen: false })
  }

  renderEmojiPicker = () => {
    const footer = document.getElementById('chatFooter')
    const pickerBottom = footer ? footer.getBoundingClientRect().height : 60
    return (
      <div className="" style={{width: '50px'}}>
        <ClickOutsideTracker handleClickOutside={this.closeEmojiPicker}>
            <Picker
              native={true}
              onClick={this.addEmoji}
              style={{ position: 'absolute', bottom: pickerBottom + 'px', zIndex: "1", display: this.state.showEmojiPicker ? "block" : "none" }}
            />
        </ClickOutsideTracker>
        <a href="" onClick={(event) => this.toggleEmojiPicker(event)} >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 0C18.6274 0 24 5.37261 24 12.0001C24 18.6275 18.6274 24.0001 12 24.0001C5.37258 24.0001 0 18.6275 0 12.0001C0 5.37261 5.37258 0 12 0ZM12 1.67443C6.29734 1.67443 1.67442 6.29737 1.67442 12.0001C1.67442 17.7027 6.29734 22.3257 12 22.3257C17.7027 22.3257 22.3256 17.7027 22.3256 12.0001C22.3256 6.29737 17.7027 1.67443 12 1.67443ZM9.2923 14.3992C9.45553 15.7606 10.6161 16.7974 12 16.7974C13.3772 16.7974 14.534 15.7704 14.7055 14.4173C14.7623 13.9691 15.1717 13.6517 15.62 13.7085C16.0683 13.7653 16.3856 14.1747 16.3288 14.623C16.0541 16.7913 14.2039 18.4337 12 18.4337C9.78528 18.4337 7.92914 16.7755 7.66758 14.594C7.61378 14.1454 7.93388 13.738 8.38254 13.6842C8.83119 13.6304 9.23851 13.9505 9.2923 14.3992ZM6.97674 7.53492C7.9015 7.53492 8.65116 8.28458 8.65116 9.20934C8.65116 10.1341 7.9015 10.8838 6.97674 10.8838C6.05199 10.8838 5.30233 10.1341 5.30233 9.20934C5.30233 8.28458 6.05199 7.53492 6.97674 7.53492ZM17.0233 7.53492C17.948 7.53492 18.6977 8.28458 18.6977 9.20934C18.6977 10.1341 17.948 10.8838 17.0233 10.8838C16.0985 10.8838 15.3488 10.1341 15.3488 9.20934C15.3488 8.28458 16.0985 7.53492 17.0233 7.53492Z" fill="#888888"/>
          </svg>
        </a>
      </div>
    )
  }

  render() {

    return (
      <div className="input_wrap d-flex">
        { this.renderEmojiPicker() }
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 0C18.6274 0 24 5.37261 24 12.0001C24 18.6275 18.6274 24.0001 12 24.0001C5.37258 24.0001 0 18.6275 0 12.0001C0 5.37261 5.37258 0 12 0ZM12 1.67443C6.29734 1.67443 1.67442 6.29737 1.67442 12.0001C1.67442 17.7027 6.29734 22.3257 12 22.3257C17.7027 22.3257 22.3256 17.7027 22.3256 12.0001C22.3256 6.29737 17.7027 1.67443 12 1.67443ZM9.2923 14.3992C9.45553 15.7606 10.6161 16.7974 12 16.7974C13.3772 16.7974 14.534 15.7704 14.7055 14.4173C14.7623 13.9691 15.1717 13.6517 15.62 13.7085C16.0683 13.7653 16.3856 14.1747 16.3288 14.623C16.0541 16.7913 14.2039 18.4337 12 18.4337C9.78528 18.4337 7.92914 16.7755 7.66758 14.594C7.61378 14.1454 7.93388 13.738 8.38254 13.6842C8.83119 13.6304 9.23851 13.9505 9.2923 14.3992ZM6.97674 7.53492C7.9015 7.53492 8.65116 8.28458 8.65116 9.20934C8.65116 10.1341 7.9015 10.8838 6.97674 10.8838C6.05199 10.8838 5.30233 10.1341 5.30233 9.20934C5.30233 8.28458 6.05199 7.53492 6.97674 7.53492ZM17.0233 7.53492C17.948 7.53492 18.6977 8.28458 18.6977 9.20934C18.6977 10.1341 17.948 10.8838 17.0233 10.8838C16.0985 10.8838 15.3488 10.1341 15.3488 9.20934C15.3488 8.28458 16.0985 7.53492 17.0233 7.53492Z" fill="#888888"/>
        </svg> */}
        <div style={{width: '85%'}}
          id="conversation-text-editor"
          className="conversation-text-editor"
          contentEditable
        />
        <a
        href="#"
        className="chat-plus"
        onClick={(e) => {
          e.preventDefault();
          this.openFilesUploader();
        }}
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12.3297 12.1499L9.85969 14.6199C8.48969 15.9899 8.48969 18.1999 9.85969 19.5699C11.2297 20.9399 13.4397 20.9399 14.8097 19.5699L18.6997 15.6799C21.4297 12.9499 21.4297 8.50992 18.6997 5.77992C15.9697 3.04992 11.5297 3.04992 8.79969 5.77992L4.55969 10.0199C2.21969 12.3599 2.21969 16.1599 4.55969 18.5099" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </a>
      
        <a href="" onClick={(ev) => this.sendMessage(ev)} data-toggle="tooltip" title={ window.t("chat.conversation.send") } >
          {/* <button> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18.0703 8.51013L9.51026 4.23013C3.76026 1.35013 1.40026 3.71013 4.28026 9.46013L5.15026 11.2001C5.40026 11.7101 5.40026 12.3001 5.15026 12.8101L4.28026 14.5401C1.40026 20.2901 3.75026 22.6501 9.51026 19.7701L18.0703 15.4901C21.9103 13.5701 21.9103 10.4301 18.0703 8.51013ZM14.8403 12.7501H9.44026C9.03026 12.7501 8.69026 12.4101 8.69026 12.0001C8.69026 11.5901 9.03026 11.2501 9.44026 11.2501H14.8403C15.2503 11.2501 15.5903 11.5901 15.5903 12.0001C15.5903 12.4101 15.2503 12.7501 14.8403 12.7501Z" fill="#329A80"/>
            </svg>
          {/* </button> */}
        </a>
        
        { this.state.fileUploaderIsOpen &&
        <ChatFileUploader
          ref="filesUploader"
          open={true}
          subscription={this.props.subscription}
          handleFileUploaderClose={this.closeFilesUploader}
          conversation={this.props.conversation}
        />
        }
      </div>
    )
  }

}

export default ConversationMessageForm
