import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import ImageEditor from "@uppy/image-editor"
import Tus from "@uppy/tus"
import Russian from '@uppy/locales/lib/ru_RU'

import '@uppy/image-editor/dist/style.css'

export default class extends Controller {
  static targets = [ "categoriesButton", "currentList" ]

  connect(){
    tinyMceDefault = {
      language: 'ru',
      promotion: false,
      branding: false,
      plugins:
      //   'preview importcss autolink autosave save directionality code visualblocks visualchars fullscreen image link table charmap pagebreak nonbreaking anchor advlist lists help charmap quickbars',
      'preview importcss autolink autosave save directionality code visualblocks visualchars fullscreen image link table charmap pagebreak nonbreaking anchor advlist lists help charmap',
      menubar: 'file edit view insert format tools table',
      menu: {
        file: { title: 'File', items: 'newdocument restoredraft | preview | export print | deleteallconversations' },
        edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
        view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments' },
        insert: { title: 'Insert', items: 'uploadImage link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime' },
        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
        tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
        table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
        help: { title: 'Help', items: 'help' }
      },
      contextmenu: 'link image table',
      toolbar:
        'undo redo bold italic underline strikethrough fontselect fontsizeselect formatselect alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist uploadImageButton preview | forecolor backcolor removeformat | pagebreak | charmap | insertfile link anchor | ltr rtl ',
      toolbar_sticky: true,
      file_picker_types: 'image',
      suffix: '.min',
      relative_urls: false,
      // image_list: [
      //   {title: 'My image 1', value: 'https://avatars.dzeninfra.ru/get-zen_doc/9099118/pub_64a52479860a33194f5f192b_64a52499860a33194f5f2638/scale_1200'},
      //   {title: 'My image 2', value: 'https://avatars.dzeninfra.ru/get-zen_doc/9366206/pub_64a43494860a33194f2abfc2_64a434ce860a33194f2acdee/scale_1200'}
      // ],
      setup: (editor) => {
        editor.ui.registry.addMenuItem('uploadImage', {
          icon: 'image',
          text: window.t('add_image'),
          onAction: function () {
            addImage(editor)
          }
        });

        editor.ui.registry.addButton('uploadImageButton', {
          icon: 'image',
          tooltip: window.t('add_image'),
          onAction: (_) => addImage(editor)
        });
      },
      //skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
      //content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default')
    }

    var editBlocks = document.getElementsByClassName('tinymce')  
    for(let i=0;i<editBlocks.length;i++){
      tinymce.init(Object.assign({ target: editBlocks[i].closest('.tinymce') }, tinyMceDefault))
    }
  }

  saveContent(){
    var editor = tinymce.get('distribution_message_body')
    // myContent.render("tinymce")
    var myContent = editor.getContent()
    console.log(myContent)
    editor.setContent(myContent)
  }
  
  disconnect() {
    tinymce.remove()
  }

  showCategories() {
    if(this.currentListTarget.options[this.currentListTarget.selectedIndex].getAttribute('data-mode') == "categories"){
      this.categoriesButtonTarget.hidden = false
    }
    else{
      this.categoriesButtonTarget.hidden = true
    }
  }

}

var tinyMceDefault

function addImage(editor){
    const locale = window.locale == "ru" ? Russian : 'en_EN'
    const imageWidth = 300;
    const imageHeight = 300;

    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      },
      autoProceed: false,
      locale: locale
    })
    uppy.use(Tus, {endpoint: window.upload_endpoint, chunkSize: 5*1024*1024})
    uppy.use(Dashboard, {
      proudlyDisplayPoweredByUppy: false,
      autoOpenFileEditor: true,
      hideCancelButton: true
    })
    uppy.use(ImageEditor, {
      target: Dashboard,
      quality: 1,
      cropperOptions: {
        viewMode: 1,
        background: true,
        autoCropArea: 0.8,
        responsive: true,
        // aspectRatio: 1 / 1,
        croppedCanvasOptions: {
          width: imageWidth,
          height: imageHeight
        },
      },
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
    })

    uppy.on('upload-success', (file, response) => {
      let fileData = {title: file.name,
                      file_orig_name: file.name,
                      file_url: response.uploadURL,
                      file_size: file.size,
                      file_type: file.meta.type}
      createFile(fileData, editor);
      uppy.getPlugin('Dashboard').closeModal();
    });
    uppy.getPlugin('Dashboard').openModal();
}

function createFile(fileData, editor) {
  const parameters = JSON.stringify({file : fileData});
  const token = document.getElementsByName("csrf-token")[0].content;

  fetch('/api/v1/files.json', {
    body: parameters,
    method: 'POST',
    credentials: "include",
    headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
    },
  })
  .then(response => {
    return response.json();
  })
  .then(data => {
    editor.insertContent(`<img src="${data.url}">`)
  });
}
