import React, { useState, useEffect } from 'react'
import { client } from '../Client'
import { Button } from 'react-bootstrap'
import { toastrNotification } from '../../helpers/Toastr'
import Loader from '../../helpers/Loader'
import PackageItemsModal from './PackageItemsModal'
import PackageItemsDeleteModal from './PackageItemsDeleteModal'
import arrayMove from 'array-move'

export default function PackageItems({course, history, location, match, onUpdateCourse}) {
  const [loading, setLoading] = useState(false)
  const [packageItems, setPackageItems] = useState([])

  useEffect(() => {
    fetchPackageItems()
  }, [])

  const fetchPackageItems = () => {
    setLoading(true)
    client.getCoursePackageItems(course.id).then(items => {
      setPackageItems(items)
      setLoading(false)
    })
  }

  const handleSortEnd = (oldIndex, newIndex) => {
    const sortedPackageItems = arrayMove(packageItems, oldIndex, newIndex)
    setPackageItems(sortedPackageItems)
    client.sortPackageItems(course.id, sortedPackageItems.map(pi => pi.id))
  }

  return (
    <div className="container">
      <div className="card bg-transparent border course-creator-content mb-5">
          <div className="card-body">
            <div className="bs-stepper-content">
              <div className="d-sm-flex justify-content-between align-items-center">
                <h4>{ window.t('course.package_items')}</h4>
                <div className="dropdown">
                  <button className="btn btn-primary-soft dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                     { window.t('actions') }
                  </button>
                  <div className="dropdown-menu">
                    <PackageItemsModal course={course} />
                    { packageItems.length > 0 &&
                      <PackageItemsDeleteModal course={course} packageItems={packageItems} />
                    }
                  </div>
                </div>
              </div>
              <hr/>

              <div className="row">
                <div className="col-12">
                  { loading && <Loader /> }

                  { !loading && packageItems.length > 0 &&
                    <div className="table-responsive border-0 rounded-3">
                      <table className="table align-middle p-4 mb-0">
                        <tbody className="border-top-0">
                          { packageItems.map((packageItem, index) => (
                            <tr key={packageItem.id}>
                              <td>
                                <div className="d-md-flex align-items-center justify-content-between">
                                  <div className="d-sm-flex align-items-center">
                                    <div className="w-100px w-md-80px mb-2 mb-md-0">
                                      <img src={packageItem.course.image_url} className="rounded" alt="" />
                                    </div>
                                    <h6 className="mb-0 ms-sm-3 mt-2 mt-lg-0">{ packageItem.course.title }</h6>
                                    <h6 className="mb-0 ms-sm-3 mt-2 mt-lg-0">{ packageItem.course.videos_ready ? 
                                    ''
                                    : 
                                    <p className="badge bg-warning mt-3"> { window.t('course.video_transcoding')} </p>
                                    }
                                    </h6>
                              		</div>
                                  <div className="mt-2 mt-md-0">
                                    { index !== 0 &&
                                      <button className="btn btn-sm btn-dark-soft btn-round me-2 mb-0" onClick={e => handleSortEnd(index, index - 1)} >
                                        <i className="fas fa-fw fa-chevron-up"></i>
                                      </button>
                                    }
                                    { index !== packageItems.length - 1 &&
                                      <button className="btn btn-sm btn-dark-soft btn-round mb-0" onClick={e => handleSortEnd(index, index + 1)} >
                                        <i className="fas fa-fw fa-chevron-down"></i>
                                      </button>
                                    }
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  }

                  { !loading && packageItems.length == 0 &&
                    <div className="text-center mt-10">
                      { window.t('course.no_available_package_courses') }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
