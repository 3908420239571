import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

let hashTagStart = false
let hashTagPos = -1

export default class extends Controller {

  static targets = [ "field" ]

  static values = {
    urlUpdate: String
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
    this.fieldTarget.addEventListener("keyup", this.changeHashTag);
  }

  initializeTribute(){
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'title',
      values: this.fetchCourses,
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  changeHashTag(event){
    if (event.key == '#'){
       console.log("start hash tag")
       hashTagStart = true
       hashTagPos = this.editor.getPosition()
    }
    if (event.key == ' ' && hashTagStart == true) {
      console.log("end hash tag")
      let position = this.editor.getPosition()
      let range = [hashTagPos-1, position]
      this.editor.setSelectedRange(range)
      let hashTagString = this.editor.getDocument().getStringAtRange(range)
      this.editor.insertHTML(`<a href="#">${hashTagString}</a>`)
      hashTagStart = false
      hashTagPos = -1
    }
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  fetchCourses(text, callback){
    fetch(`/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(courses => callback(courses))
      .catch(error => callback([]))
  }

  _pasteHtml(html, startPos, endPos){
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - 1, position])
    this.editor.deleteInDirection("backward")
  }
}

