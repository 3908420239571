import React, { Component } from 'react'
import ActionCable from 'actioncable'
import { client } from '../Client'
import ConversationMessages from './ConversationMessages'
import ConversationMessageForm from './ConversationMessageForm'
import { toastrNotification } from '../../helpers/Toastr'
import noavatar from '../../images/noavatar.jpg'
import leftArrow from '../../images/mobile/left-arrow.svg'

class ConversationMobile extends Component {

  state = {
    subscriptionActive: false,
    search: ""
  }

  subscription = {}
  channelName = "ConversationChannel"

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip()

    let _this = this;

    let listener = {
      received(data) {
        switch (data.action) {
          case 'create_message':
            _this.addNewMessage(data)
            break
          case 'delete_message':
            _this.deleteMessage(data)
            break
        }
        //console.log("received, channel:" + _this.channelName, data)
      }
    }

    ActionCable.debugging = true
    window.cable = ActionCable.createConsumer("/cable")
    this.subscription = window.cable.subscriptions.create({ channel: this.channelName, conversation_id: this.props.conversation.id }, listener)
    if (this.subscription) { this.setState({ subscriptionActive: true }) }
  }

  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => { console.log('unsubscribed'); this.subscription.unsubscribe() }, 100);
    }
  }

  addNewMessage(data) {
    if (data.success == true) {
      const messageData = JSON.parse(data.message)
      this.refs.messagesList.addMessage(messageData)
      if (this.refs.messageForm !== undefined && data.author_id === this.props.currentUserId) {
        this.refs.messageForm.reset()
      }
    } else {
      if (data.author_id === this.props.currentUserId) {
        toastrNotification({ type: 'success', message: data.errors })
      }
    }
  }

  deleteMessage(data) {
    this.refs.messagesList.deleteMessageFromList(data.message_id)
  }

  onSearchChange = (evt) => {
    this.setState({ search: evt.target.value })
  }

  showMembersList = (e) => {
    e.preventDefault()
    $('#showMemberList').toggleClass('active')
    $('body').toggleClass('show-sidebar-right')

    if ($('.chat-sidebar-right').length > 0) {
      new PerfectScrollbar('.chat-sidebar-right', {
        suppressScrollX: true
      })
    }
  }

  showMainMenu = (e) => {
    e.preventDefault()
    let chatMenu = document.querySelector('.room_actions')
    if (chatMenu) {
      chatMenu.style.top = e.clientY + 'px'
      chatMenu.classList.toggle('show')
    }
    let alreadyOpen = document.querySelector('.edit')
    if (alreadyOpen) alreadyOpen.classList.remove('edit')
    let closestMessage = e.target.closest('.message')
    if (closestMessage) closestMessage.classList.add('edit')
  }

  closeMainMenu = (e) => {
    e.preventDefault()
    if (document.querySelector('.room_actions')) document.querySelector('.room_actions').classList.remove('show')
  }

  deleteChat = (e) => {
    e.preventDefault()
    const href = e.target.getAttribute('href')
    const modalEl = document.getElementById('deleteConfirmationModal');

    if (modalEl && href) {
      const buttonEl = modalEl.querySelector('form.button_to');
      const confirmTextEl = modalEl.querySelector('.confirm-text');
      const confirmTitleEl = modalEl.querySelector('.confirm-title');
      buttonEl.setAttribute('action', href);
      confirmTextEl.innerHTML = window.t('chat.conversation.delete_modal.direct_conversation.warning');
      confirmTitleEl.innerHTML = window.t("chat.conversation.delete_modal.confirm");
      $('#close').on('click', function () {
        $('body').removeClass('shadow')
      })
      $('#cancel').on('click', function () {
        $('body').removeClass('shadow')
      })
      $('#delete-confirm').on('click', function () {
        $('body').removeClass('shadow')
      })

      $('body').addClass('shadow')
      // const modal = new bootstrap.Modal(modalEl, {});
      // modal.show();
    }
    if (document.querySelector('.room_actions')) document.querySelector('.room_actions').classList.toggle('show')
  }

  clearChat = (e) => {
    e.preventDefault()
    const href = e.target.getAttribute('href')
    const modalEl = document.getElementById('deleteConfirmationModal');

    if (modalEl && href) {
      const buttonEl = modalEl.querySelector('form.button_to');
      const confirmTextEl = modalEl.querySelector('.confirm-text');
      const confirmTitleEl = modalEl.querySelector('.confirm-title');
      buttonEl.setAttribute('action', href);
      confirmTextEl.innerHTML = window.t('chat.conversation.delete_modal.course_group_conversation.warning');
      confirmTitleEl.innerHTML = window.t("chat.conversation.delete_modal.course_group_conversation.confirm");
      $('#close').on('click', function () {
        $('body').removeClass('shadow')
      })
      $('#cancel').on('click', function () {
        $('body').removeClass('shadow')
      })
      $('#delete-confirm').on('click', function () {
        $('body').removeClass('shadow')
      })

      $('body').addClass('shadow')
      // const modal = new bootstrap.Modal(modalEl, {});
      // modal.show();
    }
    if (document.querySelector('.room_actions')) document.querySelector('.room_actions').classList.toggle('show')
  }

  pupilCourseViewer() {
    if (document.querySelector('.chat')) {
      return true
    }
    else {
      return false
    }
  }

  render() {

    const { conversation, canUpdateConversation, currentUserId } = this.props

    return (
      <>
        <div className="chat-bg wrapper">
          {conversation.course_id == null || conversation.course_id != null && !this.pupilCourseViewer() ?
            <div className="block_bg fixed-chat">
              <div className="block_top">
                <div className="container d-flex justify-content-between align-items-center header_right">
                  <div className="top_left d-flex align-items-center">
                    <a href={Routes.conversations_path()} className="d-flex align-items-center ">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M9.57 5.92993L3.5 11.9999L9.57 18.0699" stroke="#061310" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M20.4999 12H3.66992" stroke="#061310" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg> */}
                      <img src={leftArrow} />
                    </a>
                    <div className="chat_info d-flex">
                      <img src={noavatar} alt="" />
                      {/* <img src={conversation.participants[2].avatar_url || noavatar} alt="" /> */}
                      <div className="d-flex flex-column">
                        <a href="#" className="chat_title">{conversation.name}</a>
                        {conversation.convo_mode == "group" && conversation.course_id &&
                          <span>{conversation.messages_size} сообщений</span>
                        }
                        {conversation.convo_mode == "direct" && conversation.participants[0].online == true &&
                          <span>В сети</span>
                        }
                        {conversation.convo_mode == "direct" && conversation.participants[0].online != true &&
                          <span className='offline'>Не в сети</span>
                        }
                      </div>
                    </div>
                  </div>
                  <a href="#" onClick={this.showMainMenu} >

                    <svg className="open_action" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3Z" fill="#292D32" />
                      <path d="M12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17Z" fill="#292D32" />
                      <path d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" fill="#292D32" />
                    </svg>
                  </a>
                </div>

              </div>
            </div>
            :
            ''
          }
          {conversation.convo_mode == "direct" &&

            <ul className="room_actions">
              {/* <li>
                  <a href="#" className="d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M22 22L20 20" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      Поиск
                  </a>
              </li> */}
              <li id="favoriteLink">
                <a href={Routes.toggle_favorite_conversation_path(conversation.id)} onClick={(e) => this.closeMainMenu(e)} className="d-flex" data-method="put" data-remote="true" data-toggle="tooltip" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={conversation.favorited_by.includes(currentUserId) ? "#ffc107" : "none"}>
                    <path d="M13.7299 3.50989L15.4899 7.02989C15.7299 7.51989 16.3699 7.98989 16.9099 8.07989L20.0999 8.60989C22.1399 8.94989 22.6199 10.4299 21.1499 11.8899L18.6699 14.3699C18.2499 14.7899 18.0199 15.5999 18.1499 16.1799L18.8599 19.2499C19.4199 21.6799 18.1299 22.6199 15.9799 21.3499L12.9899 19.5799C12.4499 19.2599 11.5599 19.2599 11.0099 19.5799L8.01991 21.3499C5.87991 22.6199 4.57991 21.6699 5.13991 19.2499L5.84991 16.1799C5.97991 15.5999 5.74991 14.7899 5.32991 14.3699L2.84991 11.8899C1.38991 10.4299 1.85991 8.94989 3.89991 8.60989L7.08991 8.07989C7.61991 7.98989 8.25991 7.51989 8.49991 7.02989L10.2599 3.50989C11.2199 1.59989 12.7799 1.59989 13.7299 3.50989Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {conversation.favorited_by.includes(currentUserId) ? window.t('chat.conversation.remove_from_favorites') : window.t('chat.conversation.add_to_favorites')}
                </a>
              </li>
              <li>
                <a href={Routes.conversation_path(conversation.id)} onClick={this.deleteChat} className="d-flex" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.8504 9.13989L18.2004 19.2099C18.0904 20.7799 18.0004 21.9999 15.2104 21.9999H8.79039C6.00039 21.9999 5.91039 20.7799 5.80039 19.2099L5.15039 9.13989" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.3301 16.5H13.6601" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 12.5H14.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {window.t("delete")}
                </a>
              </li>
            </ul>
          }
          {conversation.convo_mode == "group" &&
            <ul className="room_actions">
              <li id="favoriteLink">
                <a href={Routes.toggle_favorite_conversation_path(conversation.id)} onClick={(e) => this.closeMainMenu(e)} className="d-flex" data-method="put" data-remote="true" data-toggle="tooltip" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={conversation.favorited_by.includes(currentUserId) ? "#ffc107" : "none"}>
                    <path d="M13.7299 3.50989L15.4899 7.02989C15.7299 7.51989 16.3699 7.98989 16.9099 8.07989L20.0999 8.60989C22.1399 8.94989 22.6199 10.4299 21.1499 11.8899L18.6699 14.3699C18.2499 14.7899 18.0199 15.5999 18.1499 16.1799L18.8599 19.2499C19.4199 21.6799 18.1299 22.6199 15.9799 21.3499L12.9899 19.5799C12.4499 19.2599 11.5599 19.2599 11.0099 19.5799L8.01991 21.3499C5.87991 22.6199 4.57991 21.6699 5.13991 19.2499L5.84991 16.1799C5.97991 15.5999 5.74991 14.7899 5.32991 14.3699L2.84991 11.8899C1.38991 10.4299 1.85991 8.94989 3.89991 8.60989L7.08991 8.07989C7.61991 7.98989 8.25991 7.51989 8.49991 7.02989L10.2599 3.50989C11.2199 1.59989 12.7799 1.59989 13.7299 3.50989Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {conversation.favorited_by.includes(currentUserId) ? window.t('chat.conversation.remove_from_favorites') : window.t('chat.conversation.add_to_favorites')}
                </a>
              </li>
              {/* {!conversation.support && canUpdateConversation && currentUserId == conversation.admin_id &&
              <li>
                <a href={Routes.candidates_conversation_participants_path(conversation.id)} data-modal="true" className="d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
                </a>
              </li>
              }

              {!conversation.support && canUpdateConversation && currentUserId == conversation.admin_id &&
                <li>
                  <a id="showMemberList" onClick={this.showMembersList} href="#" className="d-flex" data-toggle="tooltip" title={window.t('chat.conversation.participants_list')} className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    {conversation.participants_size}
                  </a>
                </li>
              }
              
              {!conversation.support && canUpdateConversation && currentUserId == conversation.admin_id &&
              <li>
                <a href={Routes.edit_conversation_path(conversation.id)} data-modal="true" className="d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
                </a>
              </li>
              } */}

              {(conversation.course_id == null || conversation.course_id != null && conversation.admin_id == currentUserId) &&
                <li>
                  <a href={Routes.conversation_clear_group_chat_path(conversation.id)} onClick={this.clearChat} className="d-flex" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18.8504 9.13989L18.2004 19.2099C18.0904 20.7799 18.0004 21.9999 15.2104 21.9999H8.79039C6.00039 21.9999 5.91039 20.7799 5.80039 19.2099L5.15039 9.13989" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.3301 16.5H13.6601" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.5 12.5H14.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {window.t("clear")}
                  </a>
                </li>
              }
            </ul>

          }

          <ConversationMessages
            subscription={this.subscription}
            conversation={conversation}
            currentUserId={currentUserId}
            canUpdateConversation={canUpdateConversation}
            search={this.state.search}
            ref="messagesList"
          />


          {this.state.subscriptionActive &&
            <ConversationMessageForm
              subscription={this.subscription}
              conversation={this.props.conversation}
              ref="messageForm"
            />
          }
        </div>
      </>
    )
  }

}

export default ConversationMobile
