import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkBox", "form", "searchFormValue", "searchValue", "priceFormValue", "popularityFormValue", "searchGptFormValue"]

  execute(e){
    let checkbox = e.target.querySelector(".check-box")

    if(checkbox.checked) {
      checkbox.checked = false
      e.target.classList.remove("active")

    }
    else {
      checkbox.checked = true
      e.target.classList.add("active")
    }

    this.sendRequest()
  }

  sendRequest(clearGptData = true){
    this.searchFormValueTarget.value = this.searchValueTarget.value
    if(clearGptData) {
      this.searchGptFormValueTarget.value = ""
    }

    this.formTarget.requestSubmit()
  }

  clearAndSend(){
    this.searchFormValueTarget.value = ""
    this.priceFormValueTarget.value = ""
    this.popularityFormValueTarget.value = ""

    this.sendRequest()
  }

  applyPopularity(e){
    e.preventDefault()

    if(this.popularityFormValueTarget.value === "popular") {
      this.popularityFormValueTarget.value = ""
    }
    else {
      this.popularityFormValueTarget.value = "popular"
    }

    this.sendRequest()
  }

  searchWithChatGpt(e){
    e.preventDefault()

    this.searchGptFormValueTarget.value = this.searchValueTarget.value
    this.sendRequest(false)
  }

  applyPiceLevel(e){
    e.preventDefault()

    this.priceFormValueTarget.value = e.target.getAttribute("data-filter")

    this.sendRequest()
  }
}
