import React, { useState } from 'react'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import {
  Modal, ModalBody, ModalFooter,
  ModalHeader, ModalTitle, Button
} from 'react-bootstrap'

export default function FilePreview({ file }) {
  const [show, setShow] = useState(false)

  return (
    <>
      <Button
        variant="none"
        style={{ padding: "0px" }}
        onClick={() => setShow(true)}
      >
        {file.is_image ?
          <img src={file.url} alt="" style={{ width: '200px' }}></img>
          :
          <div className='border border-info border-2 rounded p-1'>{file.name}</div>
        }
      </Button>
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {file.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: file.url }]}
            style={{ minHeight: '400px' }}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false
              }
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}