import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  show(event) {
    event.preventDefault();
    const href = this.element.getAttribute('href')
    const modalEl = document.getElementById('deleteConfirmationModal');
    if (modalEl && href) {
      const buttonEl = modalEl.querySelector('form.button_to');
      const confirmTextEl = modalEl.querySelector('.confirm-text');
      const confirmTitleEl = modalEl.querySelector('.confirm-title');
      buttonEl.setAttribute('action', href);
      confirmTextEl.innerHTML = this.element.dataset.confirmText;
      confirmTitleEl.innerHTML = this.element.dataset.confirmTitle;
      $('#close').on('click', function(){
        $('body').removeClass('shadow')
      })
      $('#cancel').on('click', function(){
        $('body').removeClass('shadow')
      })
      $('#delete-confirm').on('click', function(){
        $('body').removeClass('shadow')
      })
      
      $('body').addClass('shadow')  
      // const modal = new bootstrap.Modal(modalEl, {});
      // modal.show();
    }
  }
}