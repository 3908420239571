import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  connect(){
  //   $(document).on('click', function (e) {
  //     $('.time_zone').css('top', '150%')

  //  });
  }

  language(event) {
    $('.language').css('top', '50%')
  }

  timeZone(event){
    $('.time_zone').css('top', '50%')
  }

  chooseLanguage(event){
    $(event.target).find('.lang').prop('checked', true)
    $(event.target).next('.lang').prop('checked', true)
    $('.language').css('top', '-100%')
  }

  changeTimezone(event){
    event.target.form.submit()
    $('.time_zone').css('top', '150%')
  }

  closeTimezone(event){
    $('.time_zone').css('top', '150%')
  }

  closeLanguage(event){
    $('.language').css('top', '150%')
  }
}