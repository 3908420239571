import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  open(e){
    e.preventDefault()
    this.element.classList.add("filter_open")
  }

  close(e){
    e.preventDefault()
    this.element.classList.remove("filter_open")
  }

  toggle(e){
    e.preventDefault()
    this.element.classList.toggle("filter_open")    
  }
}
