import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import ImageEditor from "@uppy/image-editor"
import Tus from "@uppy/tus"
import Russian from '@uppy/locales/lib/ru_RU'

import '@uppy/image-editor/dist/style.css'

export default class extends Controller {

  static targets = ['image', 'imageInput']

  static values = {
    urlUpdate: String
  }


  allowUpload(event){
    $('#upload-link').toggleClass('restrict-upload')
    
  }

  changeImage(event) {
    if($('#upload-link').hasClass('restrict-upload'))return
    const locale = window.locale == "ru" ? Russian : 'en_EN'
    const imageWidth = this.imageTarget.dataset.width || 600;
    const imageHeight = this.imageTarget.dataset.height || 600;

    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      },
      autoProceed: false,
      locale: locale
    })
    uppy.use(Tus, {endpoint: window.upload_endpoint, chunkSize: 5*1024*1024})
    uppy.use(Dashboard, {
      proudlyDisplayPoweredByUppy: false,
      autoOpenFileEditor: true,
      hideCancelButton: true
    })
    uppy.use(ImageEditor, {
      target: Dashboard,
      quality: 1,
      cropperOptions: {
        viewMode: 1,
        background: true,
        autoCropArea: 0.8,
        responsive: true,
        aspectRatio: 1 / 1,
        croppedCanvasOptions: {
          width: imageWidth,
          height: imageHeight
        },
      },
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
    })

    uppy.on('upload-success', (file, response) => {
      let fileData = {title: file.name,
                      file_orig_name: file.name,
                      file_url: response.uploadURL,
                      file_size: file.size,
                      file_type: file.meta.type}
      createFile(fileData, this.imageInputTarget);
      this.imageTarget.src = file.preview;
      uppy.getPlugin('Dashboard').closeModal();
    });

    uppy.getPlugin('Dashboard').openModal();
  }
}

function createFile(fileData, imageInput) {
  const parameters = JSON.stringify({file : fileData});
  const token = document.getElementsByName("csrf-token")[0].content;

  fetch('/api/v1/files.json', {
    body: parameters,
    method: 'POST',
    credentials: "include",
    headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
    },
  })
  .then(response => {
    return response.json();
  })
  .then(data => {
    imageInput.value = data["content_id"];
  });
}
