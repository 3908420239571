import * as tus from 'tus-js-client'
import { client } from '../Client'

function uploadTrixAttachment(attachment) {
  const file = attachment.file;

  let upload = new tus.Upload(file, {
      endpoint: window.upload_endpoint,
      // Retry delays will enable tus-js-client to automatically retry on errors
      retryDelays: [0, 3000, 5000, 10000, 20000],
      // Attach additional meta data about the file for the server
      chunkSize: 5*1024*1024,
      metadata: {
        filename: file.name,
        filetype: file.type
      },
      // Callback for errors which cannot be fixed using retries
      onError: function(error) {
        console.log("Failed because: " + error)
      },
      // Callback for reporting upload progress
      onProgress: function(bytesUploaded, bytesTotal) {
        const progress = (bytesUploaded / bytesTotal * 100).toFixed(2)
        console.log(bytesUploaded, bytesTotal, progress + "%")
        attachment.setUploadProgress(progress)
      },
      // Callback for once the upload is completed
      onSuccess: function() {
        console.log("Download %s from %s", upload.file.name, upload.url)
        const file = upload.file
        const fileData = {title: file.name,
                          file_orig_name: file.name,
                          file_url: upload.url,
                          file_size: file.size,
                          file_type: file.type}
        client.createFile(fileData)
        .then(response => {
          if (response.success) {
            attachment.setAttributes({
              url: response.url,
              href: response.url
            })
          }
        })
      }
  })

  upload.start()
}

document.addEventListener("trix-file-accept", (e) => {
  //temporary disable uploading files with trix
  e.preventDefault()
})

document.addEventListener("trix-attachment-add", event => {
  let attachment = event.attachment;

  if (attachment.file) {
    uploadTrixAttachment(attachment);
  }
})

document.addEventListener("trix-attachment-remove", event => {
  let attachment = event.attachment;
  //console.log(attachment)
})
