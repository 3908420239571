import React from 'react'
import { client } from './../Client'
import Uppy from '@uppy/core'
import Russian from '@uppy/locales/lib/ru_RU'
import Tus from '@uppy/tus'
import { DashboardModal } from '@uppy/react'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

class HomeworkSubmissionFilesUploader extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      modalOpen: false,
      uploadedFiles: 0,
      isReady: true,
      submissionId: null,
      isLoading: false
    }

    const locale = window.locale == "ru" ? Russian : 'en_EN'

    this.uppy = new Uppy({
      restrictions: { maxNumberOfFiles: 10 },
      autoProceed: true,
      locale: locale
    }).use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5*1024*1024 })

    this.uppy.on('upload-success', (file, response) => {
      let fileData = {title: file.name,
                      file_orig_name: file.name,
                      file_url: response.uploadURL,
                      file_size: file.size,
                      file_type: file.meta.type}
      this.createSubmissionAttachment(fileData)
    })

    this.uppy.on('file-removed', (file, reason) => {
      if (file.uploadURL) {
        client.deleteFile(file.uploadURL)
      }
    })

    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }

  componentWillUnmount() {
    this.uppy.close()
  }

  open() {
    this.setState({ modalOpen: true })
  }

  close() {
    this.setState({ modalOpen: false })
  }

  createSubmissionAttachment(fileData) {
    client.createHomeworkSubmissionAttachment(this.props.homeworkId, this.props.homeworkInstanceId, this.props.submission.id, fileData)
    .then(response => {
      if (response.file_id) {
        this.setState({ uploadedFiles: this.state.uploadedFiles + 1 })
      }
    })
  }

  render () {
    const { isLoading, modalOpen } = this.state

    return (
      <div className="row">
        <div className="col-12">
          <button type="button" className="btn btn-xs btn-white" onClick={e => this.open()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
            { window.t("submission.add_files") }
          </button>
        </div>

        <DashboardModal
          uppy={this.uppy}
          closeModalOnClickOutside
          open={modalOpen}
          onRequestClose={this.close}
          target={document.body}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          showRemoveButtonAfterComplete={true}
          doneButtonHandler={this.close}
        />
      </div>
    )
  }
}

export default HomeworkSubmissionFilesUploader