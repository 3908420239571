import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "priceLevel", "select", "form", "searchVal", "searchInputField"]

  execute() {
    this.searchValTarget.value = this.searchInputFieldTarget.value
    this.formTarget.requestSubmit()
  }

  clear() {
    this.checkboxTargets.forEach(element => {
      element.checked = false
    })
    this.selectTarget.selectedIndex = 0
    this.priceLevelTarget.checked = true
  }
}
