import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["closeBtn", "inputField", "gpt", "searchValGpt"]

  hideCloseBtn(){
    setTimeout(()=> this.closeBtnTarget.classList.add("search_wrap-img--hide"), 500)
  }

  showCloseBtn(e){
    e.preventDefault()

    if(e.target.value == "") {
      this.hideCloseBtn()
    } else {
      setTimeout(()=> this.closeBtnTarget.classList.remove("search_wrap-img--hide"), 300)
    }
  }

  sendForm(e){
    e.preventDefault()
    this.hideCloseBtn()
    this.inputFieldTarget.value = "";
    this.searchValGptTarget.value = "";

    this.dispatch("sendForm")
  }

  searchWithChatGpt(e){
    e.preventDefault()
    this.searchValGptTarget.value = this.inputFieldTarget.value

    if(this.searchValGptTarget.value !== "") {
      this.dispatch("searchWithChatGpt")
    }
  }

  clearGptInput(e){
    e.preventDefault()
    this.searchValGptTarget.value = ""
  }
}
