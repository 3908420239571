import React from 'react'
import ReactDOM from 'react-dom'
import CourseCreator from './CourseCreator'
import { BrowserRouter as Router, Route } from 'react-router-dom'

document.addEventListener('turbo:load', () => {
  const creatorContainer = document.getElementById('courseCreator')
  if (creatorContainer) {
    const course = JSON.parse(creatorContainer.dataset.course),
          categories = JSON.parse(creatorContainer.dataset.categories),
          certificateTemplates = JSON.parse(creatorContainer.dataset.certificate_templates);
    ReactDOM.render(
      <Router>
        <Route
          path="/:locale/tutor/courses/:courseId"
          render={(props) => <CourseCreator {...props} course={course} categories={categories} certificateTemplates={certificateTemplates} />}
        />
      </Router>,
      creatorContainer
    );
  }
})
