import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.#load()
  }

  async #load() {
    const res = await get(`${this.urlValue}?is_being_loaded_action=true`, { responseKind: "turbo-stream" })

    console.log(res.ok);
  }
}
