import React from 'react'
import { client } from '../Client'
import cloneDeep from 'lodash/cloneDeep'
import { Card } from 'react-bootstrap'
import CourseSection from './Curriculum/CourseSection'
import Loader from '../../helpers/Loader'
import arrayMove from 'array-move'

class Curriculum extends React.Component {

  state = {
    sections: [],
    isLoading: false
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    client.getCourseSections(this.props.course.id).
      then((sections) => {
        this.setState({ sections: sections, isLoading: false })
      })

  }

  addLection = (sectionId, newLection) => {
    let newSections = cloneDeep(this.state.sections)
    const index = newSections.findIndex(section => section.id == sectionId)
    if (index !== -1) {
      newSections[index]['lections'] = [...newSections[index]['lections'], newLection]
      this.setState({ sections: newSections })
    }
  }

  updateLection = (sectionId, updatedLection) => {
    let newSections = cloneDeep(this.state.sections)
    const index = newSections.findIndex(section => section.id == sectionId)
    if (index !== -1) {
      let newLections = [...newSections[index]['lections']]
      newSections[index]['lections'] = newLections.map(lection => {
        return lection.id == updatedLection.id ? updatedLection : lection
      })
      this.setState({ sections: newSections })
    }
  }

  deleteLection = (sectionId, deletedLection) => {
    let newSections = cloneDeep(this.state.sections)
    const index = newSections.findIndex(section => section.id == sectionId)

    if (index !== -1) {
      let newLections = [...newSections[index]['lections']]
      const lectionIndex = newLections.findIndex(lection => lection.id == deletedLection.id)

      if (lectionIndex !== -1) {
        newLections.splice(lectionIndex, 1)
        newSections[index]['lections'] = newLections
        this.setState({ sections: newSections })
      }
    }
  }

  onSortEnd = (oldIndex, newIndex) => {
    const courseId = this.props.course.id
    const sortedSections = arrayMove(this.state.sections, oldIndex, newIndex)
    this.setState({ sections: sortedSections })
    const sectionIds = sortedSections.map(section => section.id)
    client.sortSections(courseId, sectionIds)
  }

  lectionsSortEnd = (sortedSection, oldIndex, newIndex) => {
    const courseId = this.props.course.id
    const newSections = this.state.sections.map(section => {
      if (section.id === sortedSection.id) {
        const sortedLections = arrayMove(section['lections'], oldIndex, newIndex)
        section['lections'] = sortedLections
        const lectionIds = sortedLections.map(lection => lection.id)
        client.sortLections(courseId, sortedSection.id, lectionIds)
      }
      return section
    })
    this.setState({ sections: newSections })
  }

  renderAiHelpModal = () => {
    return (
      <div id="aiModal" className="modal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalTitle">
              </h5>
            </div>
            <div className="modal-body">
              <textarea className="col-12" rows="10" id="modalBody"
                value={window.t('ai.generate_request')}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const locale = window.locale,
      { isLoading, sections } = this.state,
      course = this.props.course

    const newSectionLink = (
      <div className="d-sm-flex justify-content-sm-between align-items-center mb-3">
        <h5 className="mb-2 mb-sm-0"> {window.t('course.section_upload')} </h5>
        <a href={Routes.new_tutor_course_section_path(course.slug)} className="btn btn-sm btn-primary-soft mb-0">
          <i className="bi bi-plus-circle me-2"></i>
          {window.t('course.new_section')}
        </a>
      </div>
    )

    return (
      <div className="container">
        {this.renderAiHelpModal()}
        <div className="card bg-transparent border course-creator-content mb-5">
          <div className="card-body">
            <div className="bs-stepper-content">
              <h4>{window.t('course.curriculum')}</h4>
              <hr />
              <div >
                {isLoading &&
                  <Loader />
                }
                {course.status == "stream_converting"
                  ?
                  <div className="col-12 text-center mg-b-20">
                    {window.t('course.stream_converting')}
                  </div>
                  : ""
                }
                {!isLoading &&
                  <div className="row">
                    {sections.length === 0 &&
                      <div className="col-12 text-center mg-b-20">
                        {window.t('course.curriculum_intro')}
                      </div>
                    }

                    {newSectionLink}

                    <div className="accordion accordion-icon accordion-bg-light" id="courseSectionsAccordion">
                      {sections.length > 0 &&
                        sections.map((section, index) => (
                          <div className="accordion-item mb-3" key={section.id}>
                            <CourseSection
                              section={section}
                              course={course}
                              onUpdateSection={this.updateSection}
                              onAddLection={this.addLection}
                              onUpdateLection={this.updateLection}
                              onDeleteLection={this.deleteLection}
                              onSortEnd={this.onSortEnd}
                              locale={locale}
                              myIndex={index}
                              onLectionsSortEnd={this.lectionsSortEnd}
                            />
                          </div>
                        ))
                      }
                    </div>

                    {sections.length > 0 && newSectionLink}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Curriculum